/* global jQuery wc_single_product_params */
import { module as M } from 'modujs';

class ProductZoom extends M {
	init() {
		// eslint-disable-next-line camelcase
		if (!wc_single_product_params.zoom_enabled) {
			return false;
		}

		let zoomEnabled = false;
		const galleryWidth = jQuery(this.el).width();
		const $el = jQuery(this.el);
		const $image = $el.find('img');


		if ($image.data('large_image_width') > galleryWidth) {
			zoomEnabled = true;
		}

		// But only zoom if the img is larger than its container.
		if (zoomEnabled) {
			const zoomOptions = jQuery.extend({
				touch: false
				// eslint-disable-next-line camelcase
			}, wc_single_product_params.zoom_options);

			if ('ontouchstart' in document.documentElement) {
				zoomOptions.on = 'click';
			}

			$el.trigger('zoom.destroy');
			$el.zoom(zoomOptions);

			setTimeout(() => {
				if ($el.find(':hover').length) {
					$el.trigger('mouseover');
				}
			}, 100);
		}

		return true;
	}
}

export default ProductZoom;
