import $ from 'jquery';
import LMMCarousel from './LMMCarousel';

class ParcoursClientAbonnement {
	constructor() {
		this.bodyClass = 'parcours-client-abonnement';
	}

	/* clearDataCarousel = carouselId => {
		$('#' + carouselId + ' input[type=radio]:checked').prop('checked', false);
		$('#' + carouselId + ' input[type=radio]:checked').removeAttr('checked');
		$('#' + carouselId + ' .card-abo').removeClass('selected');
		let name = '';
		switch(carouselId) {
			case 'abokdo-carousel-formule-abo': 
				name = 'abokdo-facturation-abo-kdo';
				break;

			default :
				name = 'facturation';
				break;
		}
		$('#' + name).val('');
	} */
	/*
	drawCarousel = id => {
		const cardAbo = $('#'+id).find('.card-abo');
		for(let i=0; i < cardAbo.length; i+=1) {
			$(cardAbo[i]).height($(cardAbo[i]).attr('data-height'));
		}
	} */

	/*
	onInitCarousel = event => {
		const element   = event.target;
		console.log(element);
		const id = $(element).attr('id');
		console.log(id);
		let frmAbonnement = '';
		let CardAbo = '';
		switch(id) {
			case 'carousel-formule-abo' :
				frmAbonnement = '#form-abonnement';
				break;
			case 'abokdo-carousel-formule-abo' :
				frmAbonnement = '#abokdo-form-abonnement';
				CardAbo = $(frmAbonnement).find('.card-abo');
				for(let i=0; i < CardAbo.length; i+=1) {
					if($(CardAbo[i]).hasClass('canreceivefirst')) {
						$(CardAbo[i]).parent().addClass('canreceivefirst');
					} else if($(CardAbo[i]).hasClass('cantreceivefirst')) {
						$(CardAbo[i]).parent().addClass('cantreceivefirst');
					}
				}
				$('#abokdo-carousel-formule-abo').trigger('refresh.owl.carousel');
				break;				
			default:

				break;
		}
		this.drawCarousel(id);
		if($(frmAbonnement).hasClass('step-visibility')) {
			$(frmAbonnement).addClass('step-hide');
			$(frmAbonnement).removeClass('step-visibility');
			
		}
	} */

	/*
	initCarousel = name => {
		$(name).slick({
			dots: true,
			infinite: false,
			speed: 300,
			slidesToShow: 4,
			slidesToScroll: 4,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
						infinite: true,
						dots: true
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			]
		});
	} */

	init = () => {

		
		if($(document.body).hasClass(this.bodyClass)) {
			$(document).ready(() => {

				// observer.observe(document.querySelector(".firststepnextbutton a"));

				this.moveElementForWindowsSize();
				$(window).on('resize', () => {
					this.moveElementForWindowsSize();
				});

				this.showHideStep2Fixed();
				/* $(window).on('scroll', () => {
					this.showHideStep2Fixed();
				}); */

				// Carousels
				this.aboCarousel = new LMMCarousel('#carousel-formule-abo');
				this.aboCadeauCarousel = new LMMCarousel('#abokdo-carousel-formule-abo');

				/* const cardAbo = $('.card-abo');
				for(let i=0; i < cardAbo.length; i+=1) {
					$(cardAbo[i]).attr('data-height', $(cardAbo[i]).height());
				} */
				// $('.carousel-formule-abo').addClass('inblock');

				// this.initCarousel('#carousel-formule-abo');
				// this.initCarousel('#abokdo-carousel-formule-abo');

				// Steps
				this.changeSteps('step-1');

				$('#btn-goto-step-2').click( () => {
					if($('body').hasClass('page-labonnement-des-8-11-ans')) {
						$('#pour-qui-mon-enfant').prop( "checked", true );
						$('#pour-qui-mon-enfant').attr('checked', 'checked');
						this.changeSteps('step-2');
						$('body').removeClass('disabled-next-step');
						this.changeSteps('step-3-1');
					} else {
						this.changeSteps('step-2');
					}
				});
				$('#btn-goto-step-2-fixed').click( () => {
					if($('body').hasClass('page-labonnement-des-8-11-ans')) {
						$('#pour-qui-mon-enfant').prop( "checked", true );
						$('#pour-qui-mon-enfant').attr('checked', 'checked');
						this.changeSteps('step-2');
						$('body').removeClass('disabled-next-step');
						this.changeSteps('step-3-1');
					} else {
						this.changeSteps('step-2');
					}					
				});

				$('#want-receive-first-oui').change( () => {
					this.wantReceive();
				});
				$('#want-receive-first-non').change( () => {
					this.wantReceive();
				});
				$('#pour-qui-mon-enfant').change( () => {
					this.checkPourQui();
				});
				$('#pour-qui-un-autre-enfant').change( () => {
					this.checkPourQui();
				});

				$('#habite-ou-france').change( () => {
					this.checkOuHabite();
				});
				$('#habite-ou-international').change( () => {
					this.checkOuHabite();
				});
				$('#facturation').change( () => {
					this.checkFacturation();
				});
				$('#abokdo-facturation-abo-kdo').change( () => {
					this.checkFacturationAboKdo();
				});

				$('.by-step a').click( () => {
					const nextStep = $('body').attr('data-next-step');
					this.changeSteps(nextStep);
				});

				this.recevoirChange();
				this.addMessageChange();

				$('form.variations_form').submit( () => {
					if($('body').hasClass('disabled-next-step'))return false;

					let error = false;

					$('#form-abonnement .Product-variations').removeClass('error');
					$('#abokdo-form-abonnement .Product-variations').removeClass('error');

					if($('#pour-qui-mon-enfant').is(':checked')) {
						if($('#facturation').val() === '') {
							$('#form-abonnement .Product-variations').addClass('error');
							error = true;
						}
						// eslint-disable-next-line
					} else if($('#pour-qui-un-autre-enfant').is(':checked')) {

						if($('#abokdo-facturation-abo-kdo').val() === '') {
							$('#abokdo-form-abonnement .Product-variations').addClass('error');
							error = true;
						}
					} 

					if(error === false) {
						return true;
					}

					return false;
				});

				if($('body').hasClass('goToStepAbo')){
					const stepAbo = '#btn-goto-step-' + $('body').attr('data-goToStepAbo');
					if($(stepAbo).length>0){
						$(stepAbo).trigger('click');
					}
				}
			});
		}
	}

	checkFacturation = () => {
		const val = $('#facturation').val();
		if(val !== '') {
			$('body').removeClass('disabled-next-step');
		} else if(!$('body').hasClass('disabled-next-step')) {
			$('body').addClass('disabled-next-step');
		}
	}

	checkFacturationAboKdo = () => {
		const val = $('#abokdo-facturation-abo-kdo').val();
		if(val !== '') {
			$('body').removeClass('disabled-next-step');
		} else if(!$('body').hasClass('disabled-next-step')) {
			$('body').addClass('disabled-next-step');
		}

		$('#abokdo-zone-options-recevoir').addClass('step-hide');
		const cardAboKdo = $('#abokdo-form-abonnement .card-abo');
		for(let i=0; i < cardAboKdo.length; i+=1) {
			if($(cardAboKdo[i]).attr('data-value') === val) {
				if($(cardAboKdo[i]).hasClass('canreceivefirst')) {
					$('#abokdo-zone-options-recevoir').removeClass('step-hide');
				}
				$('#abokdo-aboassociated').val($(cardAboKdo[i]).attr('data-prd'));
			}
		}
		if($('#abokdo-zone-options-recevoir').hasClass('step-hide')) {
			$('#abokdo-veux-recevoir').prop( "checked", false );
		}
	}

	uncheck = id => {
		$(id).prop( "checked", false );
		$(id).removeAttr( "checked" );
	}

	checkOuHabite = () => {
		this.changeSteps('step-2-2');
		const enFranceChecked = $('#habite-ou-france').is(':checked');
		const internationalChecked = $('#habite-ou-international').is(':checked');

		this.uncheck('#want-receive-first-oui');
		this.uncheck('#want-receive-first-non');

		if(enFranceChecked) {
			$('body').removeClass('disabled-next-step');
			$('body').attr('data-next-step', 'step-2-2-1');
			$('#abokdo-hiddenlmmaddons_wherehelive').val('france');
		} else if(internationalChecked) {
			$('body').removeClass('disabled-next-step');
			$('body').attr('data-next-step', 'step-2-2-1');
			$('#abokdo-hiddenlmmaddons_wherehelive').val('international');
		} else if(!$('body').hasClass('disabled-next-step')) {
			$('body').addClass('disabled-next-step');
			$('#abokdo-hiddenlmmaddons_wherehelive').val('');
		}
	}

	checkPourQui = () => {
		this.changeSteps('step-2');
		const monEnfantChecked = $('#pour-qui-mon-enfant').is(':checked');
		const autreEnfantChecked = $('#pour-qui-un-autre-enfant').is(':checked');
		if(monEnfantChecked) {
			$('body').removeClass('disabled-next-step');
			$('body').attr('data-next-step', 'step-3-1');

			this.uncheck('#habite-ou-france');
			this.uncheck('#habite-ou-international');
			this.uncheck('#want-receive-first-oui');
			this.uncheck('#want-receive-first-non');
		} else if(autreEnfantChecked) {
			$('body').removeClass('disabled-next-step');
			$('body').attr('data-next-step', 'step-2-2');
		} else if(!$('body').hasClass('disabled-next-step')) {
			$('body').addClass('disabled-next-step');
		}
	}

	wantReceive = () => {
		if(!$('#abokdo-form-abonnement .Product-variations').hasClass('step-hide')) {
			$('html, body').animate({
				scrollTop: $('#abokdo-form-abonnement .Product-variations h2').offset().top - 50
			}, 500);
		}
		this.changeSteps('step-2-2-1');
		const wantYes = $('#want-receive-first-oui').is(':checked');
		const wantNo = $('#want-receive-first-non').is(':checked');
		$('#abokdo-form-abonnement').removeClass('want-receive');
		$('#abokdo-form-abonnement').removeClass('dont-want-receive');

		// this.clearDataCarousel();

		
		$('#abokdo-form-abonnement .facturation-abo-kdo input:radio').prop( "checked", false );
		$('#abokdo-form-abonnement .facturation-abo-kdo input:radio').removeAttr( "checked" );
		$('#abokdo-form-abonnement .facturation-abo-kdo .card-abo').removeClass( "is-selected" );

		const currentSelect = $('#abokdo-facturation-abo-kdo').val();

		const selectInForm = $('#abokdo-carousel-formule-abo > div ');
		for(let iii = 0; iii < selectInForm.length; iii+=1) {
			if($(selectInForm[iii]).attr('data-value') === currentSelect) {
				$(selectInForm[iii]).addClass('is-selected');
			}
		}
		
		// let current = 1;
		if(wantYes) {
			$('#abokdo-form-abonnement').addClass('want-receive');
			$('#abokdo-form-abonnement').attr('data-next-step', 'step-3-2');
			$('#abokdo-hiddenwantfirst').val('yes');
			$('body').removeClass('disabled-next-step');
			// current = this.aboCadeauCarousel.getItemWithClass('bestseller');
			// this.aboCadeauCarousel.setCurrent(current);
			this.aboCadeauCarousel.draw();
		} else if(wantNo) {
			$('#abokdo-form-abonnement').addClass('dont-want-receive');
			$('body').attr('data-next-step', 'step-3-2');
			$('#abokdo-hiddenwantfirst').val('no');
			$('body').removeClass('disabled-next-step');
			// current = this.aboCadeauCarousel.getItemWithClass('bestseller');
			// this.aboCadeauCarousel.setCurrent(current);
			this.aboCadeauCarousel.draw();
		} else if(!$('body').hasClass('disabled-next-step')) {
			$('body').addClass('disabled-next-step');
			$('#abokdo-hiddenwantfirst').val('');
		}
		if(!$('#abokdo-form-abonnement .Product-variations').hasClass('step-hide'))
		{
			this.checkFacturationAboKdo();
		}
	}

	changeSteps = step => {
		let scrollToId = '';
		// alert(step);
		if((step !== 'step-2') && $('body').hasClass('disabled-next-step')) {
			switch(step) {
				case 'step-2-2-1' :
					scrollToId = '#scrToWhereHeLive h2';
					break;	
				case 'step-3-1' :
				case 'step-3-2' :
					scrollToId = '#scrToWanRecFirst h2';
					break;	
				case 'step-5-2' :
					scrollToId = '#abokdo-form-abonnement .Product-variations h2';
					break;	
				default :
					scrollToId = '';// h1.title-abo-gen';
					break;
			}

			if(scrollToId !== '') {
				$('html, body').animate({
					scrollTop: $(scrollToId).offset().top - 50
				}, 500);
			}
			return false;
		}

		const listElement = ['nav.woocommerce-breadcrumb', '.want-receive-first', '.by-step', '.final-step', '.where-he-live', '.title-abonnement-general', '.title-next-step', '.bloc-ages-mobile', '.bloc-info', '.already', '.for-who', '.Block', '.Product-reviews', '.woocommerce-variation-add-to-cart', '.bloc-container', '.Product-variations', '#form-abonnement', '#abokdo-form-abonnement'];
		let listElementShow = [];
		
		$('body').addClass('disabled-next-step');

		// console.log(step);
		// alert(step);
		switch(step) {
			case 'step-2' :
				$('body').addClass('abo-header');
				listElementShow = ['.BreadcrumbAbo', '.by-step', '.title-next-step', '.for-who', '#form-abonnement', '.woocommerce-variation-add-to-cart'];
				scrollToId = '#scrToForWho h2';
				break;

			case 'step-2-2-1' :
				listElementShow = ['.BreadcrumbAbo', '.by-step', '.title-next-step', '.for-who', '.where-he-live', '#form-abonnement', '.woocommerce-variation-add-to-cart', '.want-receive-first'];
				$('body').attr('data-next-step', 'step-3-2');
				scrollToId = '#scrToWanRecFirst h2';
				break;	

			case 'step-2-2' :
				listElementShow = ['.BreadcrumbAbo', '.by-step', '.title-next-step', '.for-who', '.where-he-live', '#form-abonnement', '.woocommerce-variation-add-to-cart'];
				$('body').attr('data-next-step', 'step-2-2-1');
				scrollToId = '#scrToWhereHeLive h2';
				break;

			case 'step-3-1' :
				$('#carousel-formule-abo').addClass('step-visibility');
				if($('body').hasClass('page-labonnement-des-8-11-ans')) {
					listElementShow = ['.BreadcrumbAbo', '.by-step', '.title-next-step', '#form-abonnement', '.woocommerce-variation-add-to-cart', '.Product-variations', '.Product-variations .bloc-container'];
				} else {
					listElementShow = ['.BreadcrumbAbo', '.by-step', '.title-next-step', '.for-who', '#form-abonnement', '.woocommerce-variation-add-to-cart', '.Product-variations', '.Product-variations .bloc-container'];
				}
				// Retrait de la step age
				// listElementShow = ['.BreadcrumbAbo', '.final-step', '.title-next-step', '.for-who', '#form-abonnement', '.woocommerce-variation-add-to-cart', '.Product-variations', '.Product-variations .bloc-container'];
				$('body').attr('data-next-step', 'step-4-1');
				this.checkFacturation();
				scrollToId = '#form-abonnement h2';
				
				break;
			case 'step-3-2' :
				listElementShow = ['.BreadcrumbAbo', '.by-step', '.want-receive-first', '.title-next-step', '.where-he-live', '.for-who', '#abokdo-form-abonnement', '.woocommerce-variation-add-to-cart', '.Product-variations', '.Product-variations .bloc-container'];
				$('body').attr('data-next-step', 'step-5-2');
				this.checkFacturationAboKdo();
				scrollToId = '#abokdo-form-abonnement .Product-variations h2';
				break;	
			case 'step-4-1' :
				if($('body').hasClass('page-labonnement-des-8-11-ans')) {
					listElementShow = ['.BreadcrumbAbo', '.final-step', '.title-next-step', '#form-abonnement', '.woocommerce-variation-add-to-cart', '.Product-variations', '.Product-variations .bloc-container', '.prenom-enveloppe', '.div-age-enfant'];
				} else {
					listElementShow = ['.BreadcrumbAbo', '.final-step', '.title-next-step', '.for-who', '#form-abonnement', '.woocommerce-variation-add-to-cart', '.Product-variations', '.Product-variations .bloc-container', '.prenom-enveloppe', '.div-age-enfant'];
				}
				$('body').removeClass('disabled-next-step');
				scrollToId = 'div.prenom-enveloppe h2';
				break;	
			case 'step-5-2' :
				// listElement = ['.final-step', '.want-receive-first', '.title-abonnement-general', '.for-who', '#abokdo-form-abonnement', '.woocommerce-variation-add-to-cart', '.Product-variations', '.Product-variations .bloc-container', '#abokdo-div-pays', '.div-options'];
				listElementShow = ['.BreadcrumbAbo', '.final-step', '.where-he-live', '.want-receive-first', '.title-next-step', '.for-who', '#abokdo-form-abonnement', '.woocommerce-variation-add-to-cart', '.Product-variations', '.Product-variations .bloc-container', '.div-options'];
				scrollToId = '#abokdo-scrToOptions h2';
				$('body').removeClass('disabled-next-step');
				break;
			default :
				listElementShow = ['nav.woocommerce-breadcrumb', '.title-abonnement-general', '.bloc-ages-mobile', '.bloc-info', '.already', '.Block', '.Product-reviews'];
				$('body').addClass('disabled-next-step');
				$('body').attr('data-next-step', 'step-2');
				scrollToId = '';// h1.title-abo-gen';
				break;
		}

		for(let i=0; i < listElement.length; i+=1) {
			$(listElement[i]).addClass('step-hide');
		}

		for(let i=0; i < listElementShow.length; i+=1) {
			$(listElementShow[i]).removeClass('step-hide');
		}

		let current = 1;
		switch(step) {
			case 'step-2': 
				$('#form-abonnement').removeClass('step-visibility');
				break;
			case 'step-2-2': 
				$('#abokdo-form-abonnement').removeClass('step-visibility');
				break;

			case 'step-3-1' :
				$('#carousel-formule-abo').removeClass('step-visibility');
				
				
				if(!$('.lmm-product-abonnement').hasClass('product_cat-selection-8-11-ans')) {
					current = this.aboCarousel.getItemWithClass('bestseller');
					this.aboCarousel.setCurrent(current);
				}
				
				this.aboCarousel.draw();
				if($('.lmm-product-abonnement').hasClass('product_cat-selection-8-11-ans')) {
					$('#carousel-formule-abo .card-abo').trigger('click');
				}
				break;

			case 'step-3-2' :
				if(!$('.lmm-product-abonnement').hasClass('product_cat-selection-8-11-ans')) {
					current = this.aboCadeauCarousel.getItemWithClass('bestseller');
					this.aboCadeauCarousel.setCurrent(current);
				}				
				this.aboCadeauCarousel.draw();
				if($('.lmm-product-abonnement').hasClass('product_cat-selection-8-11-ans')) {
					const divFormulAbo = $('#abokdo-carousel-formule-abo > div');
					for(let iDivFormulAbo = 0; iDivFormulAbo < divFormulAbo.length; iDivFormulAbo+=1) {
						if($(divFormulAbo[iDivFormulAbo]).css("display") === 'block') {
							$(divFormulAbo[iDivFormulAbo]).trigger('click');
						}
					}
				} else {
					const divFormulAbo = $('#abokdo-carousel-formule-abo > div');
					for(let iDivFormulAbo = 0; iDivFormulAbo < divFormulAbo.length; iDivFormulAbo+=1) {
						if(($(divFormulAbo[iDivFormulAbo]).hasClass('bestseller')) && ($(divFormulAbo[iDivFormulAbo]).css("display") === 'block')) {
							$(divFormulAbo[iDivFormulAbo]).trigger('click');
						}
					}
				}
				break;
			default:
				
				break;
		}

		if(scrollToId !== '') {
			$('html, body').animate({
				scrollTop: $(scrollToId).offset().top - 50
			}, 500);
		}

		return true;
	}

	showHideStep2Fixed = () => {
		if($('#btn-goto-step-2').length > 0) {
			/* const scrollTop = $(window).scrollTop();
			const elPosition = $('#btn-goto-step-2').position();
			const elHeight = $('#btn-goto-step-2').outerHeight();
			const posElInScreen = elPosition.top - scrollTop + elHeight;
			if(posElInScreen < 0) {
				$('body').addClass('step1StickyButton');
			} else {
				$('body').removeClass('step1StickyButton');
			} */

			
			const observer = new IntersectionObserver(entries => {
				entries.forEach(entry => {
					if(entry.isIntersecting) {
						$('body').removeClass('step1StickyButton');
					} else {
						$('body').addClass('step1StickyButton');
					}
				});
			}, { threshold: [0] });
			observer.observe(document.querySelector("#btn-goto-step-2"));

			

			/** Bouton "Suivant" en sticky */
			/*
			const observer2 = new IntersectionObserver(entries => {
				entries.forEach(entry => {
					if(entry.isIntersecting) {
						$('body').removeClass('stickybuttons');
					} else {
						$('body').addClass('stickybuttons');
					}
				});
			}, { threshold: [0] });
			observer2.observe(document.querySelector("#lmm-abo-button-step"));

			const observer3 = new IntersectionObserver(entries => {
				entries.forEach(entry => {
					if(entry.isIntersecting) {
						$('body').removeClass('stickybuttons');
					} else {
						$('body').addClass('stickybuttons');
					}
				});
			}, { threshold: [0] });
			observer3.observe(document.querySelector("#abokdo-lmm-abo-button-step"));

			const observer4 = new IntersectionObserver(entries => {
				entries.forEach(entry => {
					if(entry.isIntersecting) {
						$('body').removeClass('stickybuttons');
					} else {
						$('body').addClass('stickybuttons');
					}
				});
			}, { threshold: [0] });
			observer4.observe(document.querySelector("#lmm-abo-button-final-step button"));

			const observer5 = new IntersectionObserver(entries => {
				entries.forEach(entry => {
					if(entry.isIntersecting) {
						$('body').removeClass('stickybuttons');
					} else {
						$('body').addClass('stickybuttons');
					}
				});
			}, { threshold: [0] });
			observer5.observe(document.querySelector("#abokdo-lmm-abo-button-final-step button"));
			*/
			/** /Bouton "Suivant" en sticky */

			
		}
	}

	moveElementForWindowsSize = () => {
		const wW = Number($(window).width());
		const parcoursClientAboBodyClass = 'body.'+this.bodyClass;
		if(wW < 980) {
			
			this.moveContent(parcoursClientAboBodyClass+' .bloc-info .ages > div', parcoursClientAboBodyClass+' .bloc-ages-mobile', 'append');
			if(wW < 650) {
				$(parcoursClientAboBodyClass).removeClass('lmmmodetab');
				$(parcoursClientAboBodyClass).addClass('lmmmodemobile');
			} else {
				$(parcoursClientAboBodyClass).removeClass('lmmmodemobile');
				$(parcoursClientAboBodyClass).addClass('lmmmodetab');
			}
			
		} else {
			$(parcoursClientAboBodyClass).removeClass('lmmmodetab');
			$(parcoursClientAboBodyClass).removeClass('lmmmodemobile');
			this.moveContent(parcoursClientAboBodyClass+' .bloc-ages-mobile > div', parcoursClientAboBodyClass+' .bloc-info .ages', 'append');
		}
	}

	recevoirChange = () => {
		$('#abokdo-veux-recevoir').change( () => {
			if($('#abokdo-veux-recevoir').prop('checked')) {
				$('#abokdo-zone-options-recevoir').addClass('show-detail');
			} else {
				$('#abokdo-zone-options-recevoir').removeClass('show-detail');
			}
		});
	}

	addMessageChange = () => {
		$('#abokdo-envoi-msg-perso').change( () => {
			if($('#abokdo-envoi-msg-perso').prop('checked')) {
				$('.zone-options-message').addClass('show-detail');
			} else {
				$('.zone-options-message').removeClass('show-detail');
			}
		});
	}

	moveContent = (from, to, where) => {
		const element = $(from).detach();
		if(where === 'prepend') {
			$(to).prepend(element);
		} else {
			$(to).append(element);
		}
	}
}

export default ParcoursClientAbonnement;
