import { module as M } from 'modujs';

class Video extends M {
	constructor(m) {
		super(m);

		this.events = {
			click: {
				button: 'toggle',
			},
		};

		this.close = this.close.bind(this);
	}

	init() {
		this.isOpen = this.el.classList.contains('is-active');

		if (this.$('video')[0]) {
			this.$('video')[0].addEventListener('ended', this.close, false);
		}
	}

	toggle() {
		if (this.isOpen) {
			return this.close();
		}

		return this.open();
	}

	open() {
		if (this.isOpen) {
			return false;
		}

		this.isOpen = true;

		this.$('video')[0].currentTime = '0';
		this.$('video')[0].play();

		this.el.classList.add('is-active');

		return true;
	}

	close() {
		if (!this.isOpen) {
			return false;
		}

		this.isOpen = false;

		this.$('video')[0].pause();
		this.$('video')[0].currentTime = '0';

		this.el.classList.remove('is-active');

		return true;
	}
}

export default Video;
