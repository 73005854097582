import { module as M } from 'modujs';

class DialogButton extends M {
	init() {
		const id = this.getData('id');

		this.el.addEventListener('click', () => {
			this.call('close', false, 'Dialog');
			this.call('open', false, 'Dialog', id);
		});
	}
}

export default DialogButton;
