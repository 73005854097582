const InputQtyCardChange = jQuery => {
	jQuery('tr.woocommerce-cart-form__cart-item').find('input.qty').on('change', function InputQt() {
		jQuery(jQuery(this).parents('tr.woocommerce-cart-form__cart-item')).find('input.qty').val(jQuery(this).val());
	});
}

const mobileQuantities = jQuery => {
	const regex = new RegExp("^/panier/.?");

	if (regex.test(window.location.pathname)) {
		InputQtyCardChange(jQuery);

		jQuery(document.body).on('updated_wc_div', () => {
			InputQtyCardChange(jQuery);
		});
	}
}

export default mobileQuantities;
