/* global Flickity */
import { module as M } from 'modujs';
import $ from 'jquery';

class ProductGallery extends M {
	init() {
		this.initPlugins();
	}

	initPlugins() {
		let NextPrev = false; 
		let GRPCells = 1;
		let PageDots = false;
		let WrapAround = true;
		let NextPrevThumbnails = false;
		let GroupCellsThumbnails = 1;

		// Affichage des boutons next et prévious que sur le parcours client et seulement en mobile 
		if($(document.body).hasClass('parcours-client-abonnement') && window.matchMedia('screen and (max-width:600px)').matches){
			NextPrev = true; 
		}

		// Affichage des page dots que sur le parcours client et seulement en mobile 
		if($(document.body).hasClass('parcours-client-abonnement') && window.matchMedia('screen and (max-width:600px)').matches){
			PageDots = true;
		}

		// Affichage d'une seule image sur les slides que sur le parcours client et seulement en mobile first
		if($(document.body).hasClass('parcours-client-abonnement') && window.matchMedia('screen and (max-width:600px)').matches){ 
			GRPCells = 1;
		}


		if($(document.body).hasClass('parcours-client-abonnement')) {
			NextPrev = true;
			WrapAround = false;
			NextPrevThumbnails = true;
			GroupCellsThumbnails = 4;
		}

		
		

		if (this.$('slider')[0]) {
			// eslint-disable-next-line new-cap
			this.slider = new Flickity(this.$('slider')[0], {
				pageDots: PageDots,
				prevNextButtons: NextPrev,
				cellSelector: '.js-item',
				wrapAround: WrapAround, 
				groupCells: GRPCells,
				lazyLoad:1,
				// cellAlign: 'left',
				// freescroll: true, 
				// resize: false,
				on: {
					ready: () => {
						this.call('update', false, 'Scroll', 'main');
					},
				},
			});

			this.slider.on('dragStart', () => {
				document.ontouchmove = e => e.preventDefault();
			});

			this.slider.on('dragEnd', () => {
				document.ontouchmove = () => true;
			});

			this.slider.on('change', () => this.call('close', null, 'Video'));
		}

		if (this.$('thumbnails')[0] && this.$('slider')[0]) {
			// eslint-disable-next-line new-cap
			this.thumbnails = new Flickity(this.$('thumbnails')[0], {
				pageDots: false,
				prevNextButtons: NextPrevThumbnails,
				cellSelector: '.js-item',
				asNavFor: this.$('slider')[0],
				contain: true,
				lazyLoad:1,
				groupCells: GroupCellsThumbnails,
				// resize: false,
				on: {
					ready: () => {
						this.call('update', false, 'Scroll', 'main');
					},
				},
			});

			this.thumbnails.on('dragStart', () => {
				document.ontouchmove = e => e.preventDefault();
			});

			this.thumbnails.on('dragEnd', () => {
				document.ontouchmove = () => true;
			});
		}
	}
}

export default ProductGallery;
