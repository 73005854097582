import { module as M } from 'modujs';
import ClipboardJS from 'clipboard';

class CopyToClipboard extends M {
	constructor(m) {
		super(m);

		const clipboard = new ClipboardJS(this.el); // eslint-disable-line no-new
		const $toast = document.querySelector('.js-toast');

		clipboard.on('success', () => {
			$toast.style.setProperty('display', 'flex');

			setTimeout(() => $toast.style.setProperty('display', 'none'), 3000)
		})
	}
}

export default CopyToClipboard;
