import $ from 'jquery';

class CartPage {
	constructor() {
		this.bodyClass = 'woocommerce-cart';
	}

	initUpdatedCheckoutEvent = () => {
		$('#showFrmCodePromo').on('click', () => {
			$('#frmCodePromo').addClass('show');
			$('#frmCodePromo .cpsearch').hide();
			$('#frmCodePromo .cperror').hide();
			$('#frmCodePromo .cperrorempty').hide();
			$('#frmCodePromo .cperrorsame').hide();
		});

		$('#cpbutton-validate').on('click', () => {
			const usedCoupons = $('.lmmcodespromos .lmmcodepromo');
			const Coupon = $('#input-codepromo').val();

			let canSubmit = true;

			$('#frmCodePromo .cpsearch').hide();
			$('#frmCodePromo .cperror').hide();
			$('#frmCodePromo .cperrorempty').hide();
			$('#frmCodePromo .cperrorsame').hide();

			if(String(Coupon).trim() === '') {
				$('#frmCodePromo .cperrorempty').show();
				canSubmit = false;
			} else {
				for(let i=0; i<usedCoupons.length; i+=1) {
					const name = String($(usedCoupons[i]).attr('data-code')).toUpperCase();
					if(String(Coupon).toUpperCase() === name) {
						$('#frmCodePromo .cperrorsame').show();
						canSubmit = false;
						break;
					}
				}
			}

			if(canSubmit) {
				const AjaxUrl = "/wp-admin/admin-ajax.php";
				$('#frmCodePromo .cpsearch').show();
				$.post(AjaxUrl, {action : 'lmmcodepromo', couponcode : Coupon}, data => {
					// console.log(data);
					if(data.return === true) {
						window.location = window.location.pathname;
					} else {
						$('#frmCodePromo .cpsearch').hide();
						$('#frmCodePromo .cperror').show();
						$('#frmCodePromo .cperrorempty').hide();
						$('#frmCodePromo .cperrorsame').hide();
					}
				}, 'JSON');
			}
		});

		$('#cpbutton-cancel').on('click', () => {
			$('#frmCodePromo').removeClass('show');
			$('#divShowFrmCodePromo').show();
			$('#frmCodePromo .cpsearch').hide();
			$('#frmCodePromo .cperror').hide();
		});
	}

	init = () => {
		if($('body').hasClass(this.bodyClass)) {
			this.initUpdatedCheckoutEvent();
		};
	}

	initCommandeInterface = () => {
		
	}
}



export default CartPage;
