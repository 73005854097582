import { module as M } from 'modujs';
// import { disableScroll, enableScroll } from 'utils/scroll';

class VideoButton extends M {
	constructor(m) {
		super(m);

		this.events = {
			click: 'play',
		};
	}

	play() {
		// console.log(this.getData('id'));
		this.call('open', null, 'Video', this.getData('id'));
	}
}

export default VideoButton;
