/* global lesminimondes */
import { module as M } from 'modujs';
import $ from 'jquery';

/**
 * LoadMore
 */
export default class LoadMore extends M {
	constructor(m) {
		super(m);

		this.events = {
			click: {
				button: 'loadMore',
			},
		};
	}

	init() {
		this.total = parseFloat(this.getData('total'));
		this.postsPerPage = parseFloat(this.getData('posts-per-page')) || 4;
		this.offset = this.$('post').length;
		this.exclude = JSON.parse(this.getData('exclude')) || [];
		this.filters = this.getData('filters');
		this.type = this.getData('type');
		this.orderby = this.getData('order');

		this.termId = this.getData('term-id') || false;
		this.taxonomy = this.getData('taxonomy') || false;

		this.action = this.getData('action');
	}

	/**
	 * LoadMore.loadMore
	 */
	loadMore() {
		/* $('.trustpilot-widget').addClass('trustpilot-widget-do');
		 */
		// load more projects with AJAX
		this.load()
			.then(response => response.text())
			.then(html => this.append(html))
			.finally(() => this.update());
	}

	/**
	 * LoadMore.load
	 */
	load() {
		const url = new URL(window.lesminimondes.ajax_url);

		const params = {
			offset: this.offset,
			posts_per_page: this.postsPerPage,
			action: this.action,
			nonce: lesminimondes.nonce,
			exclude: this.exclude,
			filters: this.filters,
			type : this.type,
			orderby : this.orderby
		};

		// console.log(params);


		if (this.termId) {
			params.term_id = this.termId;
		}

		if (this.taxonomy) {
			params.taxonomy = this.taxonomy;
		}

		Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

		// Lock everything before the request.
		this.locked();

		return fetch(url);
	}

	/**
	 * LoadMore.update
	 */
	update() {
		this.offset += this.postsPerPage;

		if (this.$('button')[0]) {
			this.$('button')[0].setAttribute('data-count', this.total - this.offset);
		}

		if (this.$('button')[0] && this.offset >= this.total) {
			this.$('footer')[0].style.setProperty('display', 'none', 'important');
		}

		const element = document.getElementsByClassName("trustpilot-widget-in-more");
		for(let i=0; i<element.length; i+=1) {
			window.Trustpilot.loadFromElement(element[i]);
		}
		$('.trustpilot-widget-in-more').removeClass('trustpilot-widget-in-more');

		// Evenement 
		$.event.trigger('boutiqueloadmore');
    
		// Ensure everything is unlocked.
		this.unlocked();

		
	}

	/**
	 * LoadMore.locked
	 */
	locked() {
		// console.info('LoadMore.locked');

		// add loading state to ajax container if exists
		this.el.classList.add('is-processing');
	}

	/**
	 * LoadMore.unlocked
	 */
	unlocked() {
		// console.info('LoadMore.unlocked');

		// Remove loading state of ajax container if exists.
		this.el.classList.remove('is-processing');
	}

	/**
	 * LoadMore.append
	 */
	append(html) {
		if (!html) {
			return;
		}
		this.$('container')[0].innerHTML += html;
	}
}
