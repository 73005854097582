import { module as M } from 'modujs';
import A from '@19h47/accordion';

class Accordion extends M {
	init() {
		this.accordion = new A(this.el);
		this.accordion.init();
	}
}

export default Accordion;
