import $ from 'jquery';

class LMMCarousel {

	constructor(CarouselName) {
		// Propriétés
		this.CarouselName = CarouselName;
		this.CarouselContainerName = CarouselName+'-lmmcarouselcontainer';
		this.CarouselContentName = CarouselName+'-lmmcarouselcontent';
		this.WidthItem = 268;
		this.MiddleItem = (this.WidthItem / 2);
		this.CurrentItem = 1;
		this.FirstDot = 0;
		this.LastDot = 0;
		this.MiddleContent = 0;
		this.LeftMin = 0;
		this.ForceGoTo = false;

		// Enregistrer la hauteur des items
		let ItemHeight = 0;
		const Items = $(this.CarouselName).find('> div');
		for(let i=0; i < Items.length; i+=1) {
			ItemHeight = $(Items[i]).outerHeight();
			$(Items[i]).attr('data-height', ItemHeight);
			$(Items[i]).addClass('item');
		}

		// Création de l'environnement
		$( '<div id="'+String(this.CarouselContainerName).replace('#', '')+'" class="lmmcarouselcontainer"><div id="'+String(this.CarouselContentName).replace('#', '')+'" class="lmmcarouselcontent"></div></div>' ).insertBefore( this.CarouselName );
		$( this.CarouselContainerName ).append('<div class="arrows"><div class="back"><span></span></div><div class="forward"><span></span></div></div>');
		$( this.CarouselContainerName ).append('<div class="dots"></div>');
		$(this.CarouselName).addClass('items');
		$(this.CarouselContentName).append($(this.CarouselName).detach());

		// Dessiner le carousel
		this.draw();

		// Evenements sur le carousel
		$(window).on('resize', () => {this.draw();});
		$( this.CarouselName ).on( "swipeleft", () => {if($(this.CarouselContainerName).hasClass('carousel')) {this.forwardEvent();}});
		$( this.CarouselName ).on( "swiperight", () => {if($(this.CarouselContainerName).hasClass('carousel')) {this.backEvent();}});
		$(this.CarouselContainerName).find('.arrows .back').on('click', this.backEvent);
		$(this.CarouselContainerName).find('.arrows .forward').on('click', this.forwardEvent);
		$(this.CarouselName).find('.item').on('click', this.itemEvent);
	}

	getItemWithClass = className => {
		const Items = $(this.CarouselContentName).find('.item');
		let NbreItems = 0;
		for(let i=0; i<Items.length; i+=1) {
			if($(Items[i]).css("display")==="block") {
				NbreItems+=1;
				if($(Items[i]).hasClass(className)) {
					return NbreItems;
				}
			}
		}
		return NbreItems;
	}

	displayItems = () => {
		const Items = $(this.CarouselContentName).find('.item');
		let NbreItems = 0;
		const Return = {
			'nbre' : 0,
			'current' : this.CurrentItem,
			'height' : 0,
			'width' : 0,
		};
		let Height = 0;
		let ItemHeight = 0;
		for(let i=0; i<Items.length; i+=1) {
			if($(Items[i]).css("display")==="block") {
				NbreItems+=1;
				if($(Items[i]).hasClass("current")) {
					Return.current = NbreItems;
				}
				$(Items[i]).attr('data-goto', NbreItems);
				// eslint-disable-next-line
				ItemHeight = parseInt($(Items[i]).attr('data-height'));
				if(ItemHeight > Height) {
					Height = ItemHeight;
				} 
			}
		}
		Return.nbre = NbreItems;
		Return.height = Height;
		Return.width = NbreItems * this.WidthItem;
		return Return;
	}

	drawDots = () => {
		const tmp = this.MiddleContent / this.WidthItem;
		const tmp2 = tmp - Math.floor(tmp);
		let ItemFirstDot = Math.floor(tmp);
		if(tmp2 > 0.5) {ItemFirstDot +=1;}
		let ItemLastDot = Math.floor(tmp);
		if(tmp2 > 0.5) {ItemLastDot +=1;}
		const NbreItems = this.DataItems.nbre;
		this.FirstDot = (ItemFirstDot+1);
		this.LastDot = (NbreItems-ItemLastDot);
		$(this.CarouselContainerName).find('.dots').empty();
		if(this.FirstDot > 1) {
			$(this.CarouselContainerName).find('.dots').append( '<span class="pg-1" data-id="1"></span>' );
		}		
		for(let i=this.FirstDot; i<=this.LastDot; i+=1) {
			$(this.CarouselContainerName).find('.dots').append( '<span class="pg-'+i+'" data-id="'+i+'"></span>' );
		}
		if(this.LastDot < NbreItems) {
			$(this.CarouselContainerName).find('.dots').append( '<span class="pg-'+NbreItems+'" data-id="'+NbreItems+'"></span>' );
		}
		$(this.CarouselContainerName).find('.dots span').on('click', this.dotEvent);
	}

	setCurrent = current => {
		this.CurrentItem = current;
		this.DataItems.current = current;
		$(this.CarouselContainerName).find('.dots').find('span').removeClass('current');
		$(this.CarouselContainerName).find('.dots').find('span.pg-'+current).addClass('current');
	}

	draw = () => {
		this.DataItems = this.displayItems();
		// const CarouselContainerWidth = $(this.CarouselContainerName).innerWidth();	
		const CarouselContainerWidth = $(this.CarouselContentName).innerWidth();	

		// console.log('CarouselContainerWidth:',CarouselContainerWidth);
		// console.log('CarouselContentWidth2:',CarouselContentWidth2);

		const wWidth = $( window ).width();

		const CarouselContentWidth = this.DataItems.width;
		this.MiddleContent = CarouselContainerWidth / 2;
		// console.log(CarouselContainerWidth+' > ' + wWidth);
		if(CarouselContentWidth <= CarouselContainerWidth) {
			$(this.CarouselName).css('left', 'auto');
			$(this.CarouselContainerName).removeClass('carousel');
			$(this.CarouselContainerName).removeClass('mobile');
		// } else if(wWidth > 480) { // Pour mettre les formule sous forme de liste
		} else if(wWidth > 200) {
			this.LeftMin = (CarouselContentWidth - CarouselContainerWidth) * -1;
			$(this.CarouselContainerName).addClass('carousel');
			$(this.CarouselContainerName).removeClass('mobile');
			this.drawDots();
			this.ForceGoTo = true;
			this.gotoItem(this.DataItems.current);

			/*
			const h = (this.DataItems.height / 2) - 30;
			$('.arrows > div').css('bottom', h+'px');
			*/
			const h = this.DataItems.height;
			$('.arrows > div').css('height', h+'px');
		} else {
			$(this.CarouselName).css('left', 'auto');
			$(this.CarouselContainerName).removeClass('carousel');
			$(this.CarouselContainerName).addClass('mobile');
		}
	}

	gotoItem = item => {
		
		if($(this.CarouselContainerName).hasClass('carousel')) {
			if((item !== this.CurrentItem) || (this.ForceGoTo)) {
				this.ForceGoTo = false;
				const PositionElement = ((item - 1) * this.WidthItem) + (this.MiddleItem);
				let Diff = this.MiddleContent - PositionElement;
				if(Diff > 0) {
					Diff = 0;
				} else if(Diff < this.LeftMin) {
					Diff = this.LeftMin;
				}
				$(this.CarouselName).animate({ left: Diff+'px' });
		
				this.CurrentItem = item;
				$(this.CarouselContainerName).find('.dots').find('span').removeClass('current');
				$(this.CarouselContainerName).find('.dots').find('span.pg-'+item).addClass('current');
			}
		}
	}

	itemEvent = event => {
		const element   = event.currentTarget;
		if($(this.CarouselContainerName).hasClass('carousel')) {
			let goto = $(element).attr('data-goto');
			// eslint-disable-next-line
			goto = parseInt(goto);
			if(goto < this.FirstDot) {
				goto = 1;
			} else if(goto > this.LastDot) {
				goto = this.DataItems.nbre;
			}
			
			// eslint-disable-next-line
			this.CurrentItem = parseInt(goto);
		}
	}

	dotEvent = event => {
		const element   = event.target;
		const id = $(element).attr('data-id');
		// eslint-disable-next-line
		this.gotoItem(parseInt(id));
	}

	backEvent = () => {
		let pg = this.CurrentItem-1;
		if(pg < this.FirstDot) {
			pg = 1;
		}else if(pg > this.LastDot) {
			pg = this.LastDot;
		}
		this.gotoItem(pg);
	}

	forwardEvent = () => {
		let pg = this.CurrentItem+1;
		if(pg > this.LastDot) {
			pg = this.DataItems.nbre;
		} else if(pg <= this.FirstDot) {
			pg = this.FirstDot;
		}
		this.gotoItem(pg);
	}









	setHeight2 = () => {
		const Items = $(this.CarouselItems).find('.item');
		let NbreItems = 0;
		let CurrentItem = 1;
		let Height = 0;
		let ItemHeight = 0;
		for(let i=0; i<Items.length; i+=1) {
			if($(Items[i]).css("display")==="block") {
				NbreItems+=1;
				if($(Items[i]).hasClass("current")) {
					CurrentItem = NbreItems;
				}
				// eslint-disable-next-line
				ItemHeight = parseInt($(Items[i]).attr('data-height'));
				if(ItemHeight > Height) {
					Height = ItemHeight;
				} 
			}
		}
		$( this.CarouselEnvName + ' .lmmcarouselcontent' ).css('height', Height);
		return CurrentItem;
	}

}

export default LMMCarousel;