import { module as M } from 'modujs';

class Ratings extends M {
	constructor(m) {
		super(m);

		this.events = {
			click: {
				button: 'handleClick',
			}
		}
	}

	handleClick({ currentTarget }) {
		this.$('input')[0].value = currentTarget.value;
		[...this.$('button')].forEach($button => $button.classList.remove('is-active'));
		currentTarget.classList.add('is-active');
		this.$('container')[0].classList.add('selected');
	}
}

export default Ratings;
