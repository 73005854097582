import $ from 'jquery';

/**
 * Bandeau cookie
 */
class BandeauCookie {

	constructor() {
		// Propriétés
		this.divIdCookie = '#BandeauCookies';
		this.cookieName = 'lmmBandeauCookie';
		this.bodyClass = 'showBandeauCookie';
		this.dayKeepCookie = 1095; // 3 ans
	}

	init() {

		$(document).ready(() => {
			const oThis = this;
			const cookie = this.getCookie(this.cookieName);
			if(cookie === '') {
				$('body').addClass(this.bodyClass);
			}

			if($(this.divIdCookie+' .btnBandeauCookie').length > 0) {
				// eslint-disable-next-line
				$(this.divIdCookie+' .btnBandeauCookie').each( function () {				
					$(this).click(() => {
						if($(this).hasClass('accept')) {
							oThis.accept();
						} else {
							oThis.refuse();
						}					
					});
				});
			}

			this.windowsResize();
			$(window).on('resize', () => {
				this.windowsResize();
			});
		});
	}

	windowsResize = () => {
		const height = $(this.divIdCookie).outerHeight();
		if($('body').hasClass(this.bodyClass)) {
			$('body').css('padding-bottom', height);
		} else {
			$('body').css('padding-bottom', 0);
		}
	}

	accept = () => {
		this.setCookie(this.cookieName, 'accept', this.dayKeepCookie);
		this.close();
	}

	refuse = () => {
		this.setCookie(this.cookieName, 'refused', this.dayKeepCookie);
		this.close();
	}

	close = () => {
		$('body').css('padding-bottom', 0);
		$('body').removeClass(this.bodyClass);
	}

	setCookie = (cname, cvalue, exdays) => {
		const d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		const expires = "expires="+d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}
	  
	getCookie = cname => {
		const name = cname + "=";
		const ca = document.cookie.split(';');
		for(let i = 0; i < ca.length; i+=1) {
			let c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

}

export default BandeauCookie;
