import modular from 'modujs';
import $ from 'jquery';


import Guid from 'vendors/Guid';
import { elements } from 'scripts/config';
import { stickyCTAListener } from 'utils/scroll';
import * as modules from '@/scripts/modules';
import mobileQuantities from './utils/mobileQuantities';
// import fetchSVG from 'utils/fetchSVG';
import ParcoursClientAbonnement from './modules/ParcoursClientAbonnement';
// import LMMCarousel from './modules/LMMCarousel';
import CheckoutPage from './modules/CheckoutPage';
import CartPage from './modules/CartPage';
import ProductFilters from './modules/ProductFilters';
import BandeauCookie from './modules/BandeauCookie';

const production = 'production' === process.env.NODE_ENV;

if (!production) {
	const guid = new Guid(12);

	guid.init();
}

const LMMKlaviyoFrmOutOfStock = 'WA6stR';

// eslint-disable-next-line
/*window.addEventListener("klaviyoForms", function(e) { 
	if ((e.detail.type === 'stepSubmit') && (e.detail.formId === LMMKlaviyoFrmOutOfStock)) {
		const productId = $('.klaviyo-form-'+LMMKlaviyoFrmOutOfStock).attr('data-productid');// 
		_learnq.push(['properties', {
			'shownHomepageForm' : productId,
		}]);


		if($('.klaviyo-form-'+LMMKlaviyoFrmOutOfStock+' input').length > 0) {
			// const productId = $('.klaviyo-form-'+LMMKlaviyoFrmOutOfStock).attr('data-productid');
			// eslint-disable-next-line
			$('.klaviyo-form-'+LMMKlaviyoFrmOutOfStock+' input').each(function() {
				if($(this).attr('id').match(/product_id_/)) {
					$(this).val(productId);
				}
			});
		}
	}
});	*/
/* window.addEventListener("klaviyoForms", function(e) { 
	if (e.detail.type === 'embedOpen') {
		if(e.detail.formId === 'WA6stR') {
			setTimeout(() => {
				const productId = $('.klaviyo-form-'+e.detail.formId).attr('data-productid');
				// eslint-disable-next-line
				$('.klaviyo-form-'+e.detail.formId+' input').each(function() {
					if($(this).attr('id').match(/product_id_/)) {
						$(this).removeClass('needsclick');
						$(this).parent().removeClass('needsclick');
						$(this).parent().parent().removeClass('needsclick');
						$(this).parent().parent().parent().removeClass('needsclick');
						// $(this).focus();
						setTimeout(() => {
							$(this).val(productId);
							$(this).attr('type', 'hidden');
						}, "100");
						$(this).parent().parent().parent().hide();
					}
				});
			}, "100");
		}
	}
}); */


// eslint-disable-next-line
$('body').on('DOMSubtreeModified', '.klaviyo-form-'+LMMKlaviyoFrmOutOfStock, function(){
	
	if(!$('.klaviyo-form-'+LMMKlaviyoFrmOutOfStock).hasClass('lmmInit')) {
		
		
		if($('.klaviyo-form-'+LMMKlaviyoFrmOutOfStock+' input').length > 0) {
			$('.klaviyo-form-'+LMMKlaviyoFrmOutOfStock).addClass('lmmInit');
			const productId = $('.klaviyo-form-'+LMMKlaviyoFrmOutOfStock).attr('data-productid');
			// eslint-disable-next-line
			$('.klaviyo-form-'+LMMKlaviyoFrmOutOfStock+' input').each(function() {
				if($(this).attr('id').match(/product_id_/)) {
					$('.klaviyo-form-'+LMMKlaviyoFrmOutOfStock).attr('data-inputid', $(this).attr('id'));
					$(this).removeClass('needsclick');
					$(this).parent().removeClass('needsclick');
					$(this).parent().parent().removeClass('needsclick');
					$(this).parent().parent().parent().removeClass('needsclick');
					$(this).parent().parent().parent().addClass('klaviyoHideField');
				} else if($(this).attr('id').match(/email_/)) {
					const inputProductId = $('.klaviyo-form-'+LMMKlaviyoFrmOutOfStock).attr('data-inputid');
					// eslint-disable-next-line
					$(this).on( "focus", function() {
						if(!$('#'+inputProductId).hasClass('lmmInit')) {
							$('#'+inputProductId).addClass('lmmInit');
							$('#'+inputProductId).focus();
							setTimeout(() => {
								$('#'+inputProductId).val(productId);
								$('#'+inputProductId).trigger('keyup');
								$('#'+inputProductId).trigger('keypress');
								$(this).focus();
							}, "100");
						}						
					});
				}
			});
		}
	}
		
});

// eslint-disable-next-line
/* document.addEventListener('trustpilot_loaded', function() {
	alert('load');
}); */

// eslint-disable-next-line
/* document.addEventListener('trustpilot_added', function() {
	alert('trustpilot_added');
}); */ 

// eslint-disable-next-line
/* document.addEventListener('trustpilot_disallowed', function() {
	alert('trustpilot_disallowed');
}); */

// eslint-disable-next-line
document.addEventListener('trustpilot_allowed', function() {
	document.lmmTacHideTrustPilot();
});


// eslint-disable-next-line new-cap
const app = new modular({
	modules,
});

// eslint-disable-next-line 
document.blockTYReload = id => {
	const AjaxUrl = "/wp-admin/admin-ajax.php";
	// eslint-disable-next-line 
	$.post(AjaxUrl, {action : 'lmmblocktyreload', orderid : id}, data => {
		console.log('1');
	}, 'JSON');
}

// eslint-disable-next-line 
document.lmmTacShowTrustPilot = () => {
	const spanCloseTP = $('.tarteaucitron-magic-block-trustpilot span.close').length;
	if(spanCloseTP === 0) {
		$( ".tarteaucitron-magic-block-trustpilot" ).append( '<span class="close">Fermer</span>' );
		// eslint-disable-next-line 
		$( ".tarteaucitron-magic-block-trustpilot span.close" ).click(function() {
			document.lmmTacHideTrustPilot();
		});
	}
	if(!$('.tarteaucitron-magic-block-trustpilot').parent().is(":visible")) {
		$('.tarteaucitron-magic-block-trustpilot').parent().addClass('tac-tp-parent-hide');
	}
	$('body').addClass('showtactrustpilot');
}
// eslint-disable-next-line 
document.lmmTacHideTrustPilot = () => {
	$('body').removeClass('showtactrustpilot');
}

// eslint-disable-next-line 
document.lmmSlugify  = str => {
	return String(str)
		.normalize('NFKD') // split accented characters into their base characters and diacritical marks
		.replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
		.trim() // trim leading or trailing whitespace
		.toLowerCase() // convert to lowercase
		.replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
		.replace(/\s+/g, '-') // replace spaces with hyphens
		.replace(/-+/g, '-'); // remove consecutive hyphens
}

// eslint-disable-next-line 
document.lmmPlaceElements = () => {
	// Déplacer le bloc produit mis en avant dans les articles en media queries
 	if(window.matchMedia('screen and (max-width:1100px)').matches){
		$('#rightblocs #produit-mis-en-avant-elm').detach().appendTo('#produit-mis-en-avant');
	} else {
		$('#produit-mis-en-avant #produit-mis-en-avant-elm').detach().appendTo('#rightblocs');
	}
 	// Déplacer le bloc produit mis en avant dans les articles en media queries
// 	if(window.matchMedia('screen and (max-width:1100px)').matches){
// 		if($('div.container-blocs > .produit-mis-en-avant').length > 0 ){
// 			$('div.container-blocs > .produit-mis-en-avant').detach().appendTo('#main > div.blog-content > div.Site-container > div.row > div.col-md-10 > div.Wysiwyg');
// 		}
// 	} else if($('#main > div.blog-content > div.Site-container > div.row > div.col-md-10 > div.Wysiwyg > .produit-mis-en-avant').length > 0 ){
// 		$('#main > div.blog-content > div.Site-container > div.row > div.col-md-10 > div.Wysiwyg > .produit-mis-en-avant').detach().appendTo('div.container-blocs');
// 	}
}

// eslint-disable-next-line 
document.lmmChooseCollectivitePrd = inp => {
	const id = $(inp).attr('data-id');
	const val = $(inp).val();
	// eslint-disable-next-line
	if(val == 0) {
		$('.Block-Form-Collectivites li.'+id).removeClass('show');
	} else {
		$('.Block-Form-Collectivites li.'+id+' span').html(val);
		$('.Block-Form-Collectivites li.'+id).addClass('show');
	}
}

// eslint-disable-next-line 
document.lmmChooseCollectiviteDateInfo = inp => {
	const dateChoose = $('#collectivite-day').val()+'/'+$('#collectivite-month').val()+'/'+ $('#collectivite-year').val();
	$('.Block-Form-Collectivites .recap-date .recapdate').hide();
	if(dateChoose === '00/00/0000') {
		$('.Block-Form-Collectivites .recap-date .recapdate.nodate').show();
	} else {
		$('.Block-Form-Collectivites .recap-date .recapdate.datechoose span.confirmDate').html(dateChoose);
		$('.Block-Form-Collectivites .recap-date .recapdate.datechoose').show();
	}
}

// eslint-disable-next-line 
document.lmmChooseCollectiviteUserInfo = inp => {
	const id = $(inp).attr('id');
	const val = $(inp).val();
	// eslint-disable-next-line
	$('.Block-Form-Collectivites .step3 .user .'+id+' span').html(val);
}

// eslint-disable-next-line 
document.lmmChooseCollectiviteStep = step => {
	if($('#lmmFrmCollectivite').hasClass('load')) {
		return false;
	}
	switch(step) {
		case 'prev-step1' :
			$('.Block-Form-Collectivites .steps').hide();
			$('.Block-Form-Collectivites .step1').show();
			$([document.documentElement, document.body]).animate({
				scrollTop: ($("#lmmFrmCollectivite").offset().top - 50)
			}, 1000);
			break;
		case 'prev-step2' :
			$('.Block-Form-Collectivites .steps').hide();
			$('.Block-Form-Collectivites .step2').show();
			$([document.documentElement, document.body]).animate({
				scrollTop: ($("#lmmFrmCollectivite").offset().top - 50)
			}, 1000);
			break;
		default:
			$('#collectivitestep').val(step);
			// eslint-disable-next-line 
			const dataJson = $( '#lmmFrmCollectivite' ).serializeArray();

			// eslint-disable-next-line
			const ajaxUrl = wc_add_to_cart_params.ajax_url;

			$('.Block-Form-Collectivites form .frmError').hide();
			$('#lmmFrmCollectivite').addClass('load');
			$('.Block-Form-Collectivites form .divCollError').removeClass('divCollError');
			$.ajax({
				type: 'post',
				url: ajaxUrl,
				// eslint-disable-next-line
				data: dataJson,

				// eslint-disable-next-line
				success: function (response) {
					const obj = JSON.parse(response);
					$('#lmmFrmCollectivite').removeClass('load');

					if(obj.success === '-1') {
						if(obj.step === 'step2') {
							$('.Block-Form-Collectivites form .frmError').html(obj.errors);
						} else {
							const errors = JSON.parse(obj.errors);
							const keys = Object.keys(errors);
							for(let i=0; i < keys.length; i+=1) {
								$('.Block-Form-Collectivites form #div-'+keys[i]).find('.msgerror').html(errors[keys[i]]);
								$('.Block-Form-Collectivites form #div-'+keys[i]).addClass('divCollError');
							}
							// eslint-disable-next-line
							$('.Block-Form-Collectivites form .frmError').html('<ul><li>Il y a des erreurs</li></ul>');
						}
						
						$('.Block-Form-Collectivites form .frmError').show();
						$([document.documentElement, document.body]).animate({
							scrollTop: ($("#lmmFrmCollectivite").offset().top - 50)
						}, 1000);
					}

					if(obj.step === 'finish') {
						$('.Block-Form-Collectivites form').hide();
						$('.Block-Form-Collectivites p.confirmation').show();
						$([document.documentElement, document.body]).animate({
							scrollTop: ($("#collectiviteConfirmation").offset().top - 50)
						}, 1000);
					} else {
						$('.Block-Form-Collectivites .steps').hide();
						$('.Block-Form-Collectivites .'+obj.step).show();
						$([document.documentElement, document.body]).animate({
							scrollTop: ($("#lmmFrmCollectivite").offset().top - 50)
						}, 1000);
					}
				},
			}); 
			break;
	}

	
	
}

function fredoHP() {
	
	if(!$('body').hasClass('home')) {
		return false;
	}
	/*
	
	let content = '';
	
	// Ajouter Fredo dans le Header
	// $( '<div id="fredoInHeader"><span></span></div>' ).insertAfter( "header.Site-header" );

	// Ajouter Fredo au niveau de la Newsletter
	$( '<div id="fredoInNL"><span></span></div>' ).insertAfter( ".bloc-newsletter .bloc-news .info-sup > div" );

	// Ajouter Fredo dans le bloc partenaire
	content = $( '.Block-partners h2' ).html();
	$( '.Block-partners h2' ).html('<span class="imgFredo"></span><span class="contentFredo">'+content+'</span>');
	$( '.Block-partners h2' ).addClass('fredo');

	// Ajouter Fredo dans le bloc Fan
	content = $( '.Block-carrousel-photos h2' ).html();
	$( '.Block-carrousel-photos h2' ).html('<span class="imgFredo"></span><span class="contentFredo">'+content+'</span>');
	$( '.Block-carrousel-photos h2' ).addClass('fredo');

	// Ajouter Fredo dans le premier carousel
	const acard = $('.Tease-product');
	const card = acard[6];
	const cardHeader = $(card).find('header');
	$( '<div id="fredoInCarrousel"><span></span></div>' ).insertAfter( cardHeader );
	*/

	return true;
}

const init = () => {
	app.init(app);
	const regex = new RegExp("^/boutique/.+");
	if (regex.test(window.location.pathname))
		stickyCTAListener($);
	mobileQuantities($)
	elements.html.classList.add('is-loaded');
	elements.html.classList.add('is-ready');
	elements.html.classList.remove('is-loading');

	const filters = new ProductFilters();
	filters.init();

	const pca = new ParcoursClientAbonnement();
	pca.init();

	const cop = new CheckoutPage();
	cop.init();

	const bc = new BandeauCookie();
	bc.init();

	const ctp = new CartPage();
	ctp.init();

	$(window).resize(() => {
	 	document.lmmPlaceElements();
	});

	$(document).ready(() => {

		// Placement Fredo sur HP
		fredoHP();


		if(($('#sticky-add-to-cart').length > 0) && ($('#prdAddToCard').length > 0)) {
			const observer = new IntersectionObserver(entries => {
				entries.forEach(entry => {
					if(entry.isIntersecting) {
						$('body').removeClass('ssatc');
					} else {
						$('body').addClass('ssatc');
					}
				});
			}, { threshold: [0] });
			observer.observe(document.querySelector("#prdAddToCard"));
		}
		



		$('#sltSubmitsortlist').on( "change", () => {
			$('form.form-filters').submit();
		});

		$('#sltsortlist').on( "change", () => {
			const val = $('#sltsortlist option:selected').val();
			window.location.href = val;
		});

		// eslint-disable-next-line
		$('.blcabocta').each(function() {	
			const id = $(this).attr('id');
			const pid =  String(id).replace('blcAboCta-', 'blockabo-');
			const age = $('#' + pid+' .Block-abonnement-little-container .item-ages li.selected span').html();
			$( "#"+id ).clone().prependTo( ".Blocks" );
			$( '.Blocks > #'+id).attr('id', 'big'+id);
			$( '#big'+id).addClass('blcaboctabig');
			$( '#big'+id+' a span').html('Je m\'abonne au '+age);
			const observer = new IntersectionObserver(entries => {
				$('.blcaboctabig').hide();
				entries.forEach(entry => {
					const btnId = entry.target.id;
					const bigBtnId='big'+btnId;
					const parentId=String(btnId).replace('blcAboCta-', 'blockabo-');
					if($('#'+parentId).css('display') === 'block') {
						if(!entry.isIntersecting) {
							$("#"+bigBtnId).show();
						}
					} else {
						$("#"+bigBtnId).hide();
					}
				});
			}, { threshold: [0] });
			observer.observe(document.querySelector("#"+id));
		});


		/*
		if($('.lmm-klaviyo-form .lmmDefaultContent').length > 0) {
			// eslint-disable-next-line
			$('.lmm-klaviyo-form .lmmDefaultContent').click(event => {
				$('#lmmTacKlaviyoPopin').show();
			});
		}

		if($('#lmmTacKlaviyoPopin .tarteaucitronAllow').length > 0) {
			// eslint-disable-next-line
			$('#lmmTacKlaviyoPopin .tarteaucitronAllow').click(event => {
				$('.lmm-klaviyo-form .lmmDefaultContent').hide();

				// eslint-disable-next-line
				tarteaucitron.userInterface.respond(document.getElementById('klaviyoAllowed'), true);
			});
		}
		*/

		if($('.Block-Form-Collectivites').length > 0) {

			// eslint-disable-next-line
			/* $('#lmmFrmCollectivite .step1 input').each(function() {
				$(this).val($(this).attr('id'));
			}); */

			// eslint-disable-next-line
			$('.Block-Form-Collectivites .step1 span.next').click(function() {
				document.lmmChooseCollectiviteStep('step1');
			});

			// eslint-disable-next-line
			$('.Block-Form-Collectivites .step2 span.return').click(function() {
				document.lmmChooseCollectiviteStep('prev-step1');
			});

			// eslint-disable-next-line
			$('.Block-Form-Collectivites .step2 span.next').click(function() {
				document.lmmChooseCollectiviteStep('step2');
			});

			// eslint-disable-next-line
			$('.Block-Form-Collectivites .step3 span.return').click(function() {
				document.lmmChooseCollectiviteStep('prev-step2');
			});

			// eslint-disable-next-line
			$('.Block-Form-Collectivites .step3 span.next').click(function() {
				document.lmmChooseCollectiviteStep('submit');
			});

			// eslint-disable-next-line
			$('.Block-Form-Collectivites .user input').each( function () {
				// eslint-disable-next-line
				$(this).change(function() {
					document.lmmChooseCollectiviteUserInfo($(this));
				});
			});

			// eslint-disable-next-line
			$('.Block-Form-Collectivites .user select').each( function () {
				// eslint-disable-next-line
				$(this).change(function() {
					document.lmmChooseCollectiviteUserInfo($(this));
				});
			});

			// eslint-disable-next-line
			$('.Block-Form-Collectivites .slt-date select').each( function () {
				// eslint-disable-next-line
				$(this).change(function() {
					document.lmmChooseCollectiviteDateInfo($(this));
				});
			});
			

			// eslint-disable-next-line	
			$('.Block-Form-Collectivites .nbre').each( function () {
				// eslint-disable-next-line
				$(this).change(function() {
					document.lmmChooseCollectivitePrd($(this));
				});
			});
		}

		if($('.menu-blog-mobile').length > 0) {
			// eslint-disable-next-line
			$('.hamburger1').click(event => {
				if($('body').hasClass('menu-open')){
					$('body').removeClass('menu-open');
				} else {
					$('body').addClass('menu-open');
				}
			})

			// eslint-disable-next-line
			$('.menu-blog-mobile .menu-overlay').click(event => {
				$('body').removeClass('menu-open');
			});

			// eslint-disable-next-line
			$('.menu-blog-mobile button.Button-times').click(event => {
				$('body').removeClass('menu-open');
			});
		}
		if($('#menu-menublog').length > 0){
			// eslint-disable-next-line	
			$('#menu-menublog > li.wsm').each( function () {
				// eslint-disable-next-line
				$(this).click( function () {
					if($(this).hasClass('open')){
						$(this).removeClass('open');
					} else {
						$('#menu-menublog > li.wsm').removeClass('open');
						$(this).addClass('open');
					}
				});
			});
		}
		
		if($('.txtTY.lmmtyorderid').length > 0 ){
			const lmmtyoid = $('.txtTY.lmmtyorderid').attr('data-orderid');
			document.blockTYReload(lmmtyoid);
		}

		// Créer le bloc dans cet article
		if($('body.single-post #main').length > 0) {
			let h2Index = 0;
			let content = '<div class="lmmtitle">DANS CET ARTICLE</div><nav><ul>';
			let mustCreate = false;
			// eslint-disable-next-line	
			$('body.single-post #main h2').each( function () {
				mustCreate = true;
				const text = $(this).text();
				let id = $(this).attr('id');
				h2Index+=1;

				if(!id) {
					id = document.lmmSlugify(text+'-'+h2Index);
				}
				$(this).attr('id', id);
				// eslint-disable-next-line	
				content += '<li><a href="#'+id+'" title="'+String(text).replace(/"/g, '&quot;')+'">'+text+'</a></li>';
			});
			content += '</ul></nav>';
			if(mustCreate) {
				$('<div id="lmmListH2"><div class="lmmListH2Container">'+content+'</div></div>').appendTo('#rightblocs');
			}
		}

		// Déplacer le bloc dans cet article sur les articles 
		/* if($('#ez-toc-container').length > 0 ){
			// $('#ez-toc-container').detach().appendTo('#main > div.blog-content > div.Site-container > div.row > div.col-md-10 > div');
			$('#ez-toc-container').detach().appendTo('#rightblocs');
		} */

		// Mettre un ID sur l'élément du produit mis en avant
		if($('#produit-mis-en-avant > div').length > 0) {
			$('#produit-mis-en-avant > div').attr('id', 'produit-mis-en-avant-elm');
		} else {
			$('#produit-mis-en-avant').addClass('nodata');
		}

		// Déplacer les éléments
		document.lmmPlaceElements();
		
		/* else {
			$('#main > div.blog-content > div.Site-container > div.row div.Wysiwyg').addClass('full');
		} */

		// S'il n'y a rien dans le div #rightblocs on met Wysiwyg en full page
		if($('#rightblocs > div').length === 0) {
			$('#main > div.blog-content > div.Site-container > div.row div.Wysiwyg').addClass('full');
		}


		$('body.single-post #main > div.blog-content > div.Site-container > div.row > div').parents('.Site-container').addClass('blog-width');

		
		// Vérifier si l'adresse de facturation est la même que livraison, si oui, laisser la checkbox cochée
		if($('body').hasClass('page-template-checkout-page')) {
			const checkOutFields = ['civilite', 'first_name', 'last_name', 'country', 'address_1', 'address_2', 'address_3', 'address_4', 'postcode', 'city'];
			let mustCheck = false;
			for(let i=0; i < checkOutFields.length; i+=1) {
				if($('#billing_'+checkOutFields[i]).val() !== $('#shipping_'+checkOutFields[i]).val()) {
					mustCheck = true;
				  }
			}
			if((mustCheck === true) && ($( "#ship-to-different-address-checkbox:checked" ).length === 0)) {
				$('#ship-to-different-address-checkbox').trigger('click');
			}
		}

		// eslint-disable-next-line
		$('.closeKlaviyoPopin').each( function (i) {		
			$(this).parents('.klaviyo-block').find('.klaviyo-block-popin').attr('id', 'klaviyopopin-'+i);
			$(this).parents('.klaviyo-block').find('.openKlaviyoPopin').attr('data-popinid', 'klaviyopopin-'+i);
			$(this).parents('.klaviyo-block').find('.closeKlaviyoPopin').attr('data-popinid', 'klaviyopopin-'+i);
			$('#klaviyopopin-'+i).detach().appendTo('body');
			$(this).click(() => {
				$('#'+$(this).attr('data-popinid')).removeClass('show');
			});
		});

		// eslint-disable-next-line
		$('.openKlaviyoPopin').each( function () {				
			$(this).click(() => {
				$('#'+$(this).attr('data-popinid')).addClass('show');
			});
		});

		// eslint-disable-next-line
		$( 'form.checkout' ).on( 'checkout_place_order', function() {
			$("#billing_phone").focus();
			$("#billing_first_name").focus();
			if($('form.woocommerce-checkout div.woocommerce-NoticeGroup ul.woocommerce-error li.lmmCountrySelectorError').length > 0) {
				$([document.documentElement, document.body]).animate({
					scrollTop: $("h1").offset().top
				}, 2000);
				return false;
			}
			return true;
		});

		/* if($('body').hasClass('parcours-client-abonnement')) {
			const lmmCarrousel = $('.lmmcarouselcontainer');
			let lmmCarId = '';
			for(let iLmmCarrousel=0; iLmmCarrousel < lmmCarrousel.length; iLmmCarrousel+=1 ) {
				lmmCarId = $(lmmCarrousel[iLmmCarrousel]).attr('id');
				const elLmmCarrousel  = document.querySelector("#"+lmmCarId);
				// eslint-disable-next-line
				elLmmCarrousel.addEventListener('touchstart', (e) => {
					// is not near edge of view, exit
					if (e.pageX > 0 && e.pageX < window.innerWidth) return;
			
					// prevent swipe to navigate back gesture
					e.preventDefault();
				});
			}
		} */


		let data = false;
		data = {
			action: 'ajaxminicart'
		};

		// Block Abonnement - Gestion des caroussel
		/*
		const numSliderBolckAbo = $('.slider-block-abo').length;
		if(numSliderBolckAbo > 0) {
			let NextPrev = false; 
			let GRPCells = 1;
			let PageDots = false;

			// Affichage des boutons next et prévious que sur le parcours client et seulement en mobile 
			if(window.matchMedia('screen and (max-width:600px)').matches){
				NextPrev = true; 
			}

			// Affichage des page dots que sur le parcours client et seulement en mobile 
			if(window.matchMedia('screen and (max-width:600px)').matches){
				PageDots = true;
			}

			// Affichage d'une seule image sur les slides que sur le parcours client et seulement en mobile first
			if(window.matchMedia('screen and (max-width:600px)').matches){ 
				GRPCells = 1;
			}

			for(let iSliderBlockAbo=0; iSliderBlockAbo < numSliderBolckAbo; iSliderBlockAbo+=1 ) {
				// eslint-disable-next-line
				new Flickity($('.slider-block-abo')[iSliderBlockAbo], {
					pageDots: PageDots,
					prevNextButtons: NextPrev,
					cellSelector: '.js-item',
					wrapAround: true, 
					groupCells: GRPCells,
					// initialIndex: 0,
					// cellAlign: 'left',
					// freescroll: true,
					// resize: true,
					// resize: false,
					
					on: {
						ready: () => {
							// this.call('update', false, 'Scroll', 'main');
						},
					},
				}).on('dragStart', () => {
					document.ontouchmove = e => e.preventDefault();
				}).on('dragEnd', () => {
					document.ontouchmove = () => true;
				});
				// eslint-disable-next-line
				new Flickity($('.slider-block-abo-thumbnails')[iSliderBlockAbo], {
					pageDots: false,
					prevNextButtons: false,
					cellSelector: '.js-item',
					asNavFor: $('.slider-block-abo')[iSliderBlockAbo],
					contain: true,
					// resize: false,
					on: {
						ready: () => {
							// this.call('update', false, 'Scroll', 'main');
						},
					},
				}).on('dragStart', () => {
					document.ontouchmove = e => e.preventDefault();
				}).on('dragEnd', () => {
					document.ontouchmove = () => true;
				});
			}
			setTimeout (() => {
				$('.Block-abonnement-container').hide();
				$('.Block-abonnement-container.par-defaut').show();
				$('.Block-abonnement-big-container').removeClass('load');
			}, 100);
		} */
		// /Block Abonnement - Gestion des caroussel

		// Block Abonnement - Gestion des ages
		// eslint-disable-next-line
		$('div.Block.Block-abonnement .abo_change-age').each( function () {				
			$(this).click(() => {
				const $this = $(this);
				const id = $this.attr('data-id');
				$('.Block-abonnement-container').hide();
				// $('.new-cta.blcabocta').hide();
				$('#'+id).show();
				const btnId = String(id).replace('blockabo-', 'bigblcAboCta-');
				$('.blcaboctabig').hide();
				$('#'+btnId).show();
				// eslint-disable-next-line
				$('#'+id).find('.lmm-blockabo-carousel').each(function() {
					const $carousel = $(this).flickity();
					$carousel.flickity('resize')
				});
			});
		});
		// /Block Abonnement - Gestion des ages

		// Correction bug sur l'abo 4-7 ans 
		/* if($('div.blcSection').hasClass('open')){
			$('div.blcSection').removeClass('open');
		} */
		// blcSection
		if($('div.blcSection').length > 0) {
			// eslint-disable-next-line
			$('div.blcSection span.title').each( function () {
				// eslint-disable-next-line
				$(this).click( function () {
					const div = $(this).parents('div.blcSection');
					if(div.hasClass('open')) {
						div.removeClass('open');
					} else {
						div.addClass('open');
					}
				});
			});
		}
		// /blcSection
		

		// Suivi de Commande
		if($('a.btnInfoSuiviCmd').length > 0) {
			// eslint-disable-next-line
			$('a.btnInfoSuiviCmd').each( function () {				
				$(this).click(() => {
					const $this = $(this);
					const infos = $this.attr('data-info');
					const orderID = $this.attr('data-orderid');
					if(infos === '') {
						$('#poppinsQuestions .infoSuiviCommande .nosuivi .numCommande').html(orderID);
						$('#poppinsQuestions .infoSuiviCommande').addClass('nosuivi');
					} else {
						const tmp = infos.split('@@@');
						let html = '';
						for(let i=0; i < tmp.length; i+=1) {
							const tmp2 = tmp[i].split('###');
							if(tmp2.length === 3) {
								html += '<li>- <a href="'+tmp2[1]+'" target="_blank">'+tmp2[2]+'</a></li>';
							} else {
								html += '<li>- <a href="'+tmp2[1]+'" target="_blank">'+tmp2[0]+'</a></li>';
							}
							
						}
						$('#poppinsQuestions .infoSuiviCommande .withsuivi .numCommande').html(orderID);
						$('#poppinsQuestions .infoSuiviCommande .withsuivi ul').html(html);
						$('#poppinsQuestions .infoSuiviCommande').removeClass('nosuivi');
					}
					$('#poppinsQuestions').addClass('infoSuiviCommande');
				});				
			});
		}
		$('#poppinsQuestions .infoSuiviCommande .close').click(event => {
			event.stopPropagation();
			$('#poppinsQuestions').removeClass('infoSuiviCommande');
		});

		// Changement d'adresse
		$('#poppinsQuestions .returnChangeOrderAddress .close').click(event => {
			event.stopPropagation();
			window.location.reload();
		});
		if($('a.change_billing_address').length > 0) {
			// eslint-disable-next-line
			$('a.change_billing_address').each( function () {				
				$(this).click(() => {
					$('#poppinsQuestions').addClass('changeBillingAddress');
				});				
			});
		}
		if($('a.change_shipping_address').length > 0) {
			// eslint-disable-next-line
			$('a.change_shipping_address').each( function () {				
				$(this).click(() => {
					$('#poppinsQuestions').addClass('changeShippingAddress');
				});				
			});
		}
		$('#poppinsQuestions .changeOrderAddress .no').click(event => {
			event.stopPropagation();
			const type = $(event.currentTarget).attr('data-type');
			$('#poppinsQuestions').removeClass(type);
		});
		$('#poppinsQuestions .changeOrderAddress .yes').click(event => {
			event.stopPropagation();
			
			const type = $(event.currentTarget).attr('data-type');

			if($('.normsaas-overlay').length > 0) {
				// eslint-disable-next-line
				const _t = LMMNormSass;
				if(type === 'changeBillingAddress') {
					// eslint-disable-next-line
					window.changeOrderAddress(type);
				} else {
					_t.changeOrderAddress('shipping');
				}
				
			} else {
				// eslint-disable-next-line
				window.changeOrderAddress(type);
			}
			
			

			
			

			// $('#poppinsQuestions').removeClass(type);
		});

		// Annulation de commande
		if($('a.lmmCancelOrder').length > 0) {
			// eslint-disable-next-line
			$('a.lmmCancelOrder').each( function () {				
				$(this).click(() => {
					const $this = $(this);
					const orderID = $this.attr('data-orderid');
					$('#poppinsQuestions .confirmCancelOrder span.numCommande').html(orderID);
					$('#poppinsQuestions .confirmCancelOrder .yes').attr('data-orderid', orderID);
					$('#poppinsQuestions').addClass('confirmCancelOrder');
				});				
			});
		}
		$('#poppinsQuestions .confirmCancelOrder .no').click(event => {
			event.stopPropagation();
			$('#poppinsQuestions').removeClass('confirmCancelOrder');
		});
		$('#poppinsQuestions .returnCancelOrder .close').click(event => {
			event.stopPropagation();
			window.location.reload();
		});
		$('#poppinsQuestions .confirmCancelOrder .yes').click(event => {
			event.stopPropagation();

			$('#poppinsQuestions .confirmCancelOrder .buttons span').hide();
			$('#poppinsQuestions .confirmCancelOrder .buttons span.process').show();

			const orderId = $(event.currentTarget).attr('data-orderid');
			let dataJson = false;
			dataJson = {
				action: 'ajaxcancelorder',
				orderId
			};

			// eslint-disable-next-line
			const ajaxUrl = wc_add_to_cart_params.ajax_url;

			$.ajax({
				type: 'post',
				url: ajaxUrl,
				// eslint-disable-next-line
				data: dataJson,

				// eslint-disable-next-line
				success: function (response) {
					const obj = JSON.parse(response);
					// 

					$('#poppinsQuestions .returnCancelOrder .content div').html(obj.message);

					$('#poppinsQuestions .returnCancelOrder').removeClass('information');
					$('#poppinsQuestions .returnCancelOrder').removeClass('success');
					$('#poppinsQuestions .returnCancelOrder').removeClass('error');
					$('#poppinsQuestions .returnCancelOrder').removeClass('question');

					if(obj.success === '-1') {
						$('#poppinsQuestions .returnCancelOrder').addClass('error');
					} else {
						$('#poppinsQuestions .returnCancelOrder').addClass('success');
					}
					

					$('#poppinsQuestions').removeClass('confirmCancelOrder');
					$('#poppinsQuestions').addClass('returnCancelOrder');
				},
			}); 

		});


		// Shipup
		if($('#shipup-container').length > 0) {
			const shipupKeyPublic = $('#shipup-container').attr('data-publickey');
			$('#shipup-container').removeAttr('data-publickey');			
			const shipupOrder = $('#shipup-container').attr('data-order');
			if((shipupKeyPublic !== '') && (shipupOrder !== '')) {
				$('#shipup-container').removeAttr('data-order');
				// eslint-disable-next-line
				const shipup = new ShipupJS.default(shipupKeyPublic);
				const element = document.getElementById('shipup-container');
				const args = {
				  language: 'fr',
				  searchEnabled: false,
				  orderNumber: shipupOrder,
				  display: 'mobile'
				};
				shipup.render(element, args);
			}
			
		}

		// eslint-disable-next-line
		const ajaxUrl = wc_add_to_cart_params.ajax_url;

		$.ajax({
			type: 'post',
			url: ajaxUrl,
			// eslint-disable-next-line
			data: data,

			// eslint-disable-next-line
			success: function (response) {
				// eslint-disable-next-line
				const obj = JSON.parse(response);

				$('#headerCounterProducts span').html(obj.num);
				if(obj.num > 0) {
					$('#headerCounterProducts').removeClass('hidden');
				} else {
					$('#headerCounterProducts').addClass('hidden');
				}

				$('.lmm_widget_shopping_cart_content').html(obj.html);
				if($('.lmm_widget_shopping_cart_content').length > 0) {
					$('.lmm_widget_shopping_cart_content .close').click(() => {
						$('.lmm_widget_shopping_cart_content').hide();
					});

					// Evenement GTM
					// eslint-disable-next-line
					//lmmGtmInitClickEvents();
				};
			},
		});

	});

	if(($('body').hasClass('page-commande')) && ($('#main > .woocommerce > .woocommerce-notices-wrapper').length > 0)) {
		$(document).ready(() => {
			$('#main > .woocommerce > .woocommerce-notices-wrapper').insertAfter(".new-checkout > .title");
		});
	}

	

	if($('input[type=text].with-count').length > 0) {
		let inputWithCount = $('input[type=text].with-count');
		let inputWithCountLength = inputWithCount.length
		for(let i = 0; i < inputWithCountLength; i+=1) {
			// eslint-disable-next-line
			inputWithCountCalculate(inputWithCount[i]);
			$(inputWithCount[i]).on('keyup', event => {
				// eslint-disable-next-line
				inputWithCountCalculate($(event.currentTarget));
			});
		}
		inputWithCount = $('textarea.with-count');
		inputWithCountLength = inputWithCount.length
		for(let i = 0; i < inputWithCountLength; i+=1) {
			// eslint-disable-next-line
			inputWithCountCalculate(inputWithCount[i]);
			$(inputWithCount[i]).on('keyup', event => {
				// eslint-disable-next-line
				inputWithCountCalculate($(event.currentTarget));
			});
		}
	}

	// eslint-disable-next-line
	$('#btn-choose-better-form').click(event => {
		
		/* if($("input[name='good-better-addcard']:checked").length === 0) {
			alert('Il faut choisir une formule.');
		} else { */
		const val = $("input[name='good-better-addcard']:checked").attr('data-url');
		window.location.href = val;
		/* } */
		
	});

	$('.btn-change-age').click(event => {
		event.stopPropagation();

		const hideCurrent = false;

		const duree = $(event.currentTarget).attr('data-duree');
		const variationId = $(event.currentTarget).attr('data-variationid');
		$('#poppinsQuestions .popinChangeAge .yes').attr('data-id', $(event.currentTarget).attr('data-id'));
		$('#poppinsQuestions .popinChangeAge .yes').attr('data-itemid', $(event.currentTarget).attr('data-itemid'));
		$('#poppinsQuestions .popinChangeAge .yes').attr('data-productid', $(event.currentTarget).attr('data-productid'));
		$('#poppinsQuestions .popinChangeAge .yes').attr('data-age', $(event.currentTarget).attr('data-age'));
		$('#poppinsQuestions .popinChangeAge .yes').attr('data-variationid', variationId);
		$('#poppinsQuestions .popinChangeAge .yes').attr('data-quantity', $(event.currentTarget).attr('data-quantity'));
		$('#poppinsQuestions .popinChangeAge .buttons span').show();
		$('#poppinsQuestions .popinChangeAge .buttons span.process').hide();


		$('#slt-change-age').find('option')
			.remove()
			.end();
		const useClass = 'elm-duree-' + duree;
		// eslint-disable-next-line		
		$('#slt-change-age-list').find('option').each(function () {
			if($(this).hasClass(useClass)) {
				const clone = $(this).clone( true );
				$('#slt-change-age').append(clone);
			}			
		});	

		

		// $('#slt-change-age option.elm-change-age').hide();
		// $('#slt-change-age option.elm-change-age.elm-duree-'+duree).show();
		
		if(hideCurrent) {
			$('#slt-change-age').val('');
			$('#slt-change-age option.elm-change-age.elm-'+variationId).hide();
		} else {
			$('#slt-change-age').val($(event.currentTarget).attr('data-variationid'));
		}

		$('#poppinsQuestions').addClass('popinChangeAge');
	});

	$('#poppinsQuestions .popinChangeAge .no').click(event => {
		event.stopPropagation();
		$('#poppinsQuestions').removeClass('popinChangeAge');
	});

	$('#poppinsQuestions .confirmChangeAge .close').click(event => {
		event.stopPropagation();
		window.location.reload();
	});


	$('#poppinsQuestions .popinChangeAge .yes').click(event => {
		event.stopPropagation();
		const subId = $(event.currentTarget).attr('data-id');
		const itemId = $(event.currentTarget).attr('data-itemid');
		const productId = $(event.currentTarget).attr('data-productid');
		const variationId = $(event.currentTarget).attr('data-variationid');
		const quantity = $(event.currentTarget).attr('data-quantity');
		const newAge = $('#slt-change-age').val();
		const canAutoChange = $('#slt-change-age option.elm-change-age.elm-'+newAge).attr('data-canautochange');
		const currentAge = $(event.currentTarget).attr('data-age');

		if(newAge === variationId) {
			$('#poppinsQuestions').removeClass('popinChangeAge');	
		} else if(currentAge === '8-11') {
			$('#poppinsQuestions').removeClass('popinChangeAge');
			$('#poppinsQuestions').addClass('contactServiceClient');
		} else if(canAutoChange === '0') {
			$('#poppinsQuestions').removeClass('popinChangeAge');
			$('#poppinsQuestions').addClass('contactServiceClient');
		} else {
			// alert('subId:'+subId+"\nitemId:"+itemId+"\nproductId:"+productId+"\nvariationId:"+variationId+"\nquantity:"+quantity+"\nnewAge:"+newAge);
			$('#poppinsQuestions .popinChangeAge .buttons span').hide();
			$('#poppinsQuestions .popinChangeAge .buttons span.process').show();

			let data = false;
			data = {
				action: 'ajaxchangeage',
				subId,
				itemId,
				productId,
				variationId,
				quantity,
				newProductId: newAge
			};

			// eslint-disable-next-line
			const ajaxUrl = wc_add_to_cart_params.ajax_url;

			$.ajax({
				type: 'post',
				url: ajaxUrl,
				// eslint-disable-next-line
				data: data,

				// eslint-disable-next-line
				success: function (response) {
					const obj = JSON.parse(response);
					/* $('#poppinsQuestions').removeClass('confirmSuspension');
					$('#poppinsQuestions').addClass('confirmSuspensionWhy');
					$('#'+mailId).parent().parent().parent().addClass('lmmCacheField');
					$('#'+mailId).focus();
					setTimeout (() => {$('#'+mailId).val(mail);}, 100); */
					$('#poppinsQuestions .confirmChangeAge .content').html(obj.message);
					$('#poppinsQuestions').removeClass('popinChangeAge');
					$('#poppinsQuestions').addClass('confirmChangeAge');
				},
			}); 

		}
	});

	// Suspendre mon abonnement
	$('#btnSubscriptionSuspension ').click(event => {
		event.stopPropagation();
		$('#poppinsQuestions').addClass('confirmSuspension');
	});
	$('#btnReactiveSubscriptionSuspension ').click(event => {
		event.stopPropagation();
		$('#poppinsQuestions').addClass('confirmReactivate');
	});

	$('#poppinsQuestions .confirmSuspension .no').click(event => {
		event.stopPropagation();
		$('#poppinsQuestions').removeClass('confirmSuspension');
	});

	$('#poppinsQuestions .contactServiceClient .close').click(event => {
		event.stopPropagation();
		$('#poppinsQuestions').removeClass('contactServiceClient');
	});

	$('#poppinsQuestions .confirmSuspensionWhy .close').click(event => {
		event.stopPropagation();
		window.location.reload();
	});

	$('#poppinsQuestions .confirmManualWhy .close').click(event => {
		event.stopPropagation();
		$('#poppinsQuestions').removeClass('confirmManualWhy');
	});

	// eslint-disable-next-line
	$('ul.lmmMenuMobile a.withsubmenu').each( function () {
		const $this = $(this);
		$this.click(event => {
			event.stopPropagation();
			const isOpen = $this.parents('li').hasClass('open');
			$('ul.lmmMenuMobile a.withsubmenu').parents('li').removeClass('open');
			if(!isOpen){
				$this.parents('li').addClass('open');
			}
		});
	});

	$('#poppinsQuestions .confirmSuspension .yes').click(event => {
		event.stopPropagation();
		$('#poppinsQuestions .confirmSuspension .buttons span').hide();
		$('#poppinsQuestions .confirmSuspension .buttons span.process').show();

		const mail = $(event.currentTarget).attr('data-mail');
		let mailId = false;
		// eslint-disable-next-line
		$('#poppinsQuestions .confirmSuspensionWhy input').each( function () {
			const $this = $(this);
			// eslint-disable-next-line
			const regex = new RegExp('^email_');
			if(regex.test($this.attr('id'))) {
				mailId = $this.attr('id');
			}
		});
		
		let data = false;
		data = {
			action: 'ajaxsubscriptionsuspension',
			subid: $(event.currentTarget).attr('data-id')
		};

		// eslint-disable-next-line
		const ajaxUrl = wc_add_to_cart_params.ajax_url;

		$.ajax({
			type: 'post',
			url: ajaxUrl,
			// eslint-disable-next-line
			data: data,

			// eslint-disable-next-line
			success: function (response) {
				$('#poppinsQuestions').removeClass('confirmSuspension');
				$('#poppinsQuestions').addClass('confirmSuspensionWhy');
				$('#'+mailId).parent().parent().parent().addClass('lmmCacheField');
				$('#'+mailId).focus();
				setTimeout (() => {$('#'+mailId).val(mail);}, 100);
			},
		}); 
	});

	$('#poppinsQuestions .confirmReactivate .no').click(event => {
		event.stopPropagation();
		$('#poppinsQuestions').removeClass('confirmReactivate');
	});

	$('#poppinsQuestions .confirmReactivate .yes').click(event => {
		event.stopPropagation();
		$('#poppinsQuestions .confirmReactivate .buttons span').hide();
		$('#poppinsQuestions .confirmReactivate .buttons span.process').show();

		let data = false;
		data = {
			action: 'ajaxsubscriptionsuspension',
			type: 'takeback',
			subid: $(event.currentTarget).attr('data-id')
		};

		// eslint-disable-next-line
		const ajaxUrl = wc_add_to_cart_params.ajax_url;

		$.ajax({
			type: 'post',
			url: ajaxUrl,
			// eslint-disable-next-line
			data: data,

			// eslint-disable-next-line
			success: function (response) {
				window.location.reload();
			},
		});
	});

	// Ajout rapide au panier
	if($('.quick-add-to-cart').length > 0) {
		$( "body" ).prepend( '<a href="/panier/" id="lmmConfirmAddInCart"><div></div></a>');



		// eslint-disable-next-line
		$('.quick-add-to-cart .toadd').each( function () {
			const $this = $(this);
			$this.click(event => {
				event.stopPropagation();

				if($this.hasClass('load'))return;

				let data = false;
				data = {
					action: 'ajaxaddtocart',
					idprd: $this.attr('data-id')
				};

				// eslint-disable-next-line
				const ajaxUrl = wc_add_to_cart_params.ajax_url;

				$('.quick-add-to-cart.prd-'+$this.attr('data-id')).addClass('load');

				$.ajax({
					type: 'post',
					url: ajaxUrl,
					// eslint-disable-next-line
					data: data,
	
					// eslint-disable-next-line
					success: function (response) {
						// eslint-disable-next-line
						const obj = JSON.parse(response);

						$('#headerCounterProducts span').html(obj.num);
						$('#headerCounterProducts').removeClass('hidden');

						$('.lmm_widget_shopping_cart_content').html(obj.html);
						if($('.lmm_widget_shopping_cart_content').length > 0) {
							$('.lmm_widget_shopping_cart_content .close').click(() => {
								$('.lmm_widget_shopping_cart_content').hide();
							});
						};
						
						$('.quick-add-to-cart.prd-'+obj.id).removeClass('load');

						$('#lmmConfirmAddInCart div').html(obj.message);
						$('#lmmConfirmAddInCart').addClass('show');
						setTimeout(() => {
							$('#lmmConfirmAddInCart').removeClass('show');
						}, 4000);
					},
				});
			});
		});
		
	}
	// Evènements du Mini-cart - Fermer le mini cart lorsqu'on clique sur la croix
	if($('.lmm_widget_shopping_cart_content').length > 0) {
		$('.lmm_widget_shopping_cart_content .close').click(() => {
			$('.lmm_widget_shopping_cart_content').hide();
		});
	};

	// Evènements du Mini-cart - Ouvrir le mini cart lorsqu'on clique sur le panier
	if($('.cart-et-mini-cart').length > 0) {
		$('.pictocartheader').click(() => {
			$('.lmm_widget_shopping_cart_content').show();
		});
	};

	// Afficher la popin crosssell
	if($('.New-cross-sells').length > 0) {
		$('.New-cross-sells .no-thanks').click(() => {
			$('body').removeClass('show-cross-sell');
		});
		$('.New-cross-sells .addToCart').click(() => {
			const $this = $('.New-cross-sells .addToCart');
			let data = false;
			data = {
				action: 'ajaxaddtocart',
				idprd: $this.attr('data-id')
			};

			// eslint-disable-next-line
			const ajaxUrl = wc_add_to_cart_params.ajax_url;

			$.ajax({
				type: 'post',
				url: ajaxUrl,
				// eslint-disable-next-line
				data: data,

				// eslint-disable-next-line
				beforeSend: function (response) {
					$('.New-cross-sells .new-cta').hide();
					$('.New-cross-sells .no-thanks').hide();
					$('.New-cross-sells .add-in-progress').show();
				},

				// eslint-disable-next-line
				success: function (response) {
					window.location.reload();
				},
			});
		}); 
		const alredyShowCrossSell = sessionStorage.getItem('lmmcrossselldisplayed');
		if(!alredyShowCrossSell) {
			$('body').addClass('show-cross-sell');
		}
		sessionStorage.setItem('lmmcrossselldisplayed', 1);
	}

	// Afficher le bloc promo
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	let scprm = urlParams.get('scprm');
	if(scprm) {
		sessionStorage.setItem('lmmscprm', 1);
	}
	scprm = sessionStorage.getItem('lmmscprm');
	if(scprm) {
		$('#LMMBlocCodePromo').show();
	}

	// Afficher un bandeau chasse au trésor
	const catcode = urlParams.get('catthecode');
	if(catcode) {
		let idBlocCatBoutique = '';
		switch(catcode) {
			case '44c9de66c5285c37f84174eb4904c034': idBlocCatBoutique = '#LMMBlocCATBoutique1'; break;
			case 'a9cbb847cb62481e3c49cda38a55035a': idBlocCatBoutique = '#LMMBlocCATBoutique2'; break;
			case '791fd441f53d6adbcae6ae9029c54c56': idBlocCatBoutique = '#LMMBlocCATBoutique3'; break;
			case '82e9cee763e186b5b4d87118b2b724b0': idBlocCatBoutique = '#LMMBlocCATBoutique4'; break;
			default: idBlocCatBoutique = ''; break;
		}
		if(idBlocCatBoutique !== '') {
			$(idBlocCatBoutique).show();
		}
	}

	// fetchSVG().then(svg => {
	// 	const div = document.createElement('div');

	// 	div.id = 'svg-sprite';
	// 	div.classList.add('d-none');
	// 	div.innerHTML = svg;

	// 	document.body.insertBefore(div, document.body.childNodes[0]);
	// });


	// $('.js-input-date').datepicker({
	// 	// altFormat: "d-m-y"
	// 	dateFormat: 'dd-mm-yy',
	// 	changeYear: true,
	// 	changeMonth: true,
	// 	yearRange: '-100:+0',
	// 	// onSelect: () => $(this).text(this.value),
	// });


	$('form.variations_form').on('found_variation', (_, variation) => {
		const { variation_id: variationId } = variation;
		const items = $('.js-product-variante-subscription-attribute li');

		// eslint-disable-next-line func-names
		items.each(function () {
			const $item = $(this);

			if ($item.data('variation-id') === variationId) {
				$item.addClass('selected');
			} else {
				$item.removeClass('selected');
			}
		});
	});
};

function inputWithCountCalculate(input) {
	const inputElement = $(input);
	const maxLength = inputElement.attr('maxlength');
	if(maxLength) {
		const inputValue = inputElement.val();
		const inputValueLength = String(inputValue).length;
		const rest = maxLength - inputValueLength;
		let phrase = ' caractères restants';
		if(rest < 2) {
			phrase = ' caractère restant';
		}
		phrase = rest + phrase;
		inputElement.parent().find('small').html(phrase);
		if(inputValueLength === 0) {
			inputElement.parent().find('small').hide();
		} else {
			inputElement.parent().find('small').show();
		}
	}
	
}

window.onload = () => {
	init();
};

window.changeOrderAddress = type => {

	$('#poppinsQuestions .'+type+' .buttons span').hide();
	$('#poppinsQuestions .'+type+' .buttons span.process').show();

	let dataJson = '';
	switch(type) {
		case 'changeBillingAddress' :
			dataJson = $( '#OrderChangeAddress_billing' ).serializeArray();
			break;
		default :
			dataJson = $( '#OrderChangeAddress_shipping' ).serializeArray();
			break;
	}
	// console.log(dataJson);

	// eslint-disable-next-line
	const ajaxUrl = wc_add_to_cart_params.ajax_url;

	$.ajax({
		type: 'post',
		url: ajaxUrl,
		// eslint-disable-next-line
		data: dataJson,

		// eslint-disable-next-line
		success: function (response) {
			const obj = JSON.parse(response);

			$('#poppinsQuestions .returnChangeOrderAddress .content div').html(obj.message);

			$('#poppinsQuestions .returnChangeOrderAddress').removeClass('information');
			$('#poppinsQuestions .returnChangeOrderAddress').removeClass('success');
			$('#poppinsQuestions .returnChangeOrderAddress').removeClass('error');
			$('#poppinsQuestions .returnChangeOrderAddress').removeClass('question');

			if(obj.success === '-1') {
				$('#poppinsQuestions .returnChangeOrderAddress').addClass('error');
			} else {
				$('#poppinsQuestions .returnChangeOrderAddress').addClass('success');
			}
			
			if(obj.type === 'billing') {
				$('#poppinsQuestions').removeClass('changeBillingAddress');
			} else {
				$('#poppinsQuestions').removeClass('changeShippingAddress');
			}					
			$('#poppinsQuestions').addClass('returnChangeOrderAddress');
		},
	}); 
}

// eslint-disable-next-line
document.LMMCountrySelectorError = (error) => {
	if($('form.woocommerce-checkout div.woocommerce-NoticeGroup').length === 0) {
		$( "form.woocommerce-checkout" ).prepend( '<div class="woocommerce-NoticeGroup woocommerce-NoticeGroup-checkout"></div>' );
	}
	if($('form.woocommerce-checkout div.woocommerce-NoticeGroup ul.woocommerce-error').length === 0) {
		$( "form.woocommerce-checkout div.woocommerce-NoticeGroup" ).prepend( '<ul class="woocommerce-error" role="alert"></ul>' );
	}
	if($('form.woocommerce-checkout div.woocommerce-NoticeGroup ul.woocommerce-error li.lmmCountrySelectorError').length > 0) {
		$('form.woocommerce-checkout div.woocommerce-NoticeGroup ul.woocommerce-error li.lmmCountrySelectorError').remove();
	}
	/*
	if($('form.woocommerce-checkout div.woocommerce-NoticeGroup ul.woocommerce-error li').length > 0) {
		$('form.woocommerce-checkout div.woocommerce-NoticeGroup ul.woocommerce-error li').remove();
	}
	*/
	$( "form.woocommerce-checkout div.woocommerce-NoticeGroup ul.woocommerce-error" ).append( '<li class="lmmCountrySelectorError">'+error+'</li>' );
}
// eslint-disable-next-line
document.LMMCountrySelectorReset = () => {
	if($('form.woocommerce-checkout div.woocommerce-NoticeGroup ul.woocommerce-error li.lmmCountrySelectorError').length > 0) {
		$('form.woocommerce-checkout div.woocommerce-NoticeGroup ul.woocommerce-error li.lmmCountrySelectorError').remove();
	}
	if($('form.woocommerce-checkout div.woocommerce-NoticeGroup ul.woocommerce-error li').length === 0) {
		$('form.woocommerce-checkout div.woocommerce-NoticeGroup').remove();
	}
}