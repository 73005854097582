import $ from 'jquery';

class CheckoutPage {
	constructor() {
		this.bodyClass = 'woocommerce-checkout';
	}

	initUpdatedCheckoutEvent = () => {

		$('.cbLnkOpenPopin').on('click', event => {
			const element   = event.currentTarget;
			const type = $(element).attr('data-type');
			if($('#popin_'+type).length > 0) {
				$('body').addClass('show-popin-' + type);
				// $('#popin_'+type).show();
			}
		});

		$('.cbLnkClosePopin').on('click', event => {
			const element   = event.currentTarget;
			const type = $(element).attr('data-type');
			let popinToClose = false;

			switch(type) {
				case 'validationterms':
					popinToClose = 'terms_and_conditions';
					$('#terms').prop( "checked", true );
					break;
				case 'notvalidateterms':
					popinToClose = 'terms_and_conditions';
					$('#terms').prop( "checked", false );
					break;
				case 'validatergpd':
					popinToClose = 'rgpd';
					$('#terms').prop( "checked", true );
					break;
				case 'notvalidatergpd':
					popinToClose = 'rgpd';
					$('#terms').prop( "checked", false );
					break;
				default:
					popinToClose = false;
					break;
			}

			if((popinToClose) && ($('#popin_'+popinToClose).length > 0)) {
				// $('#popin_'+popinToClose).hide();
				$('body').removeClass('show-popin-' + popinToClose);
			}
		});

		$('#showFrmCodePromo').on('click', () => {
			$('#frmCodePromo').addClass('show');
			$('#frmCodePromo .cpsearch').hide();
			$('#frmCodePromo .cperror').hide();
			$('#frmCodePromo .cperrorempty').hide();
			$('#frmCodePromo .cperrorsame').hide();
		});

		$('#cpbutton-validate').on('click', () => {
			const usedCoupons = $('.lmmcodespromos .lmmcodepromo');
			const Coupon = $('#input-codepromo').val();

			let canSubmit = true;

			$('#frmCodePromo .cpsearch').hide();
			$('#frmCodePromo .cperror').hide();
			$('#frmCodePromo .cperrorempty').hide();
			$('#frmCodePromo .cperrorsame').hide();

			if(String(Coupon).trim() === '') {
				$('#frmCodePromo .cperrorempty').show();
				canSubmit = false;
			} else {
				for(let i=0; i<usedCoupons.length; i+=1) {
					const name = String($(usedCoupons[i]).attr('data-code')).toUpperCase();
					if(String(Coupon).toUpperCase() === name) {
						$('#frmCodePromo .cperrorsame').show();
						canSubmit = false;
						break;
					}
				}
			}

			if(canSubmit) {
				const AjaxUrl = "/wp-admin/admin-ajax.php";
				$('#frmCodePromo .cpsearch').show();
				$.post(AjaxUrl, {action : 'lmmcodepromo', couponcode : Coupon}, data => {
					if(data.return === true) {
						$('body').trigger('update_checkout');
					} else {
						$('#frmCodePromo .cpsearch').hide();
						$('#frmCodePromo .cperror').show();
						$('#frmCodePromo .cperrorempty').hide();
						$('#frmCodePromo .cperrorsame').hide();
					}
				}, 'JSON');
			}
		});

		$('#cpbutton-cancel').on('click', () => {
			$('#frmCodePromo').removeClass('show');
			$('#divShowFrmCodePromo').show();
			$('#frmCodePromo .cpsearch').hide();
			$('#frmCodePromo .cperror').hide();
			$('#frmCodePromo .cperrorempty').hide();
			$('#frmCodePromo .cperrorsame').hide();
		});
	}

	init = () => {
		if($('body').hasClass(this.bodyClass)) {
			this.initUpdatedCheckoutEvent();

			

			// On esc key
			/* document.onkeydown = ({ key => {
				alert('coucou'+key);
				/* if (27 === key) {
					this.close();
				} */
			/* };

			$('form.checkout.woocommerce-checkout').on('submit', e => {
				alert('submit');
				e.preventDefault();
				e.stopPropagation();
				e.stopImmediatePropagation();
				return false;
			}); */
	
			$('body').on('update_checkout', () => {
				// console.log('update_checkout triggered');
			});
	
			$('body').on('updated_checkout', () => {
				// console.log('updated_checkout triggered');
				this.initUpdatedCheckoutEvent();
				

				// Organiser la page
				// this.initCommandeInterface();

			});
		};
	}

	initCommandeInterface = () => {
		
	}
}



export default CheckoutPage;
