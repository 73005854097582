const elements = {
	body: document.body,
	html: document.documentElement,
};

const breakpoints = {
	xs: 0,
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200,
	xxl: 1400,
};

const classes = {
	active: 'is-active',
	inview: 'is-in-view',
	select: 'is-selected',
};

const scroll = {
	left: 0,
	top: 0,
};

const route = {
	branches: '/branches/(?P<id>)',
	answers: '/answers/(?P<id>)',
};

const namespace = 'wp/v2';

export { elements, breakpoints, classes, scroll, route, namespace };
