/* global Flickity */
import { module as M } from 'modujs';
import $ from 'jquery';

class Carousel extends M {
	constructor(m) {
		super(m);

		this.events = {
			click: {
				next: 'next',
				previous: 'previous',
			},
		};
	}
	
	init() {
		this.carousel = false;
		this.options = {};

		this.initPlugins();
		this.initEvents();
	}

	initPlugins() {
		const withThumbnail = this.getData('with-thumbnails');

		let cellAlign = JSON.parse(this.getData('cell-center'));
		if(cellAlign === true)cellAlign = 'center';
		else cellAlign = 'left';
		let groupCells = JSON.parse(this.getData('group-cells'));
		if(groupCells === false)groupCells = false;
		else groupCells = true

		this.options = {
			pageDots: JSON.parse(this.getData('page-dots')),
			autoPlay: JSON.parse(this.getData('auto-play')),
			prevNextButtons: false,
			cellSelector: '.js-item',
			draggable: true,
			contain: true,
			cellAlign,
			lazyLoad:1,
			imagesLoaded: true,
			wrapAround: JSON.parse(this.getData('wrap-around')),
			watchCSS: JSON.parse(this.getData('watch-css')),
			groupCells,
			on: {
				ready: () => {
					this.showParent();
					this.call('update', false, 'Scroll', 'main');
				},
			},
		};

		this.carousel = new Flickity(this.$('items')[0], this.options);

		if(withThumbnail !== null) {
			this.thumbnails = new Flickity($(withThumbnail)[0], {
				pageDots: false,
				autoPlay: false,
				asNavFor: this.$('items')[0],
				prevNextButtons: false,
				cellSelector: '.js-item',
				draggable: true,
				contain: true,
				lazyLoad:1,
				cellAlign: 'left',
				imagesLoaded: true,
				wrapAround: JSON.parse(this.getData('wrap-around')),
				watchCSS: JSON.parse(this.getData('watch-css')),
				groupCells: true,
				on: {
					ready: () => {
						this.showParent();
						this.call('update', false, 'Scroll', 'main');
					},
				},
			});

			
		}
	}

	showParent() {
		const elm = $(this.el)[0];
		let elm2 = $(elm).find('.lmm-carousel-hidden');
		if(elm2.length > 0) {
			$($(elm2)[0]).removeClass('lmm-carousel-hidden');
		}
		
		elm2 = $(elm).parents('.lmm-carousel-load');
		if(elm2.length > 0) {
			setTimeout (() => {
				$(elm2[0]).find('.Block-abonnement-container').hide();
				$(elm2[0]).find('.Block-abonnement-container.par-defaut').show();
				$(elm2[0]).removeClass('lmm-carousel-load');
				$(elm2[0]).removeClass('load');
			}, 1000);
		}
	}

	initEvents() {
		if (this.$('previous')[0] && this.$('next')[0]) {
			this.carousel.on('select', index => {
				this.$('previous')[0].classList.remove('is-disabled');
				this.$('next')[0].classList.remove('is-disabled');

				if (0 === index) {
					this.$('previous')[0].classList.add('is-disabled');
				}

				if (this.carousel.slides.length - 1 === index) {
					this.$('next')[0].classList.add('is-disabled');
				}
			});
		}

		this.carousel.on('dragStart', () => {
			document.ontouchmove = e => e.preventDefault();
			this.carousel.slider.childNodes.forEach(slide => {
				slide.style.setProperty('pointer-events', 'none');
			});
		});

		this.carousel.on('dragEnd', () => {
			document.ontouchmove = () => true;
			this.carousel.slider.childNodes.forEach(slide =>
				slide.style.removeProperty('pointer-events'),
			);
		});
	}

	/**
	 * Carousel next
	 *
	 * @author	Jérémy Levron <jeremylevron@19h47.fr> (https://19h47.fr)
	 * @return	method 	Flickity next method
	 */
	next() {
		return this.carousel.next();
	}

	/**
	 * Carousel previous
	 *
	 * @author	Jérémy Levron <jeremylevron@19h47.fr> (https://19h47.fr)
	 * @return	method 	Flickity previous method
	 */
	previous() {
		return this.carousel.previous();
	}
}

export default Carousel;
