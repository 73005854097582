/* global jQuery */
import { module as M } from 'modujs';
import RadioGroup from '@19h47/radiogroup';

class VariationRadioGroup extends M {
	init() {
		const radiogroup = new RadioGroup(this.el);

		radiogroup.init();

		radiogroup.radios.forEach(radio => {
			radio.on('Radio.activate', ({ element }) => {
				const { value, name } = element.querySelector('input');

				jQuery(`select[name="${name}"]`).val(value).trigger('change');

				const products = [...document.querySelectorAll('.js-product')];

				//
				products.forEach(product => {
					const url = new URL(product.href);

					product.href = `${url.origin}${url.pathname}?${name}=${value.replace(/\s/g, '+')}`;
				});
			});
		});

		jQuery(document).on('woocommerce_update_variation_values', () => {
			radiogroup.radios.forEach(radio => {
				const { value, name } = radio.$input;

				jQuery(radio.$input).removeAttr('disabled');

				if (jQuery(`select[name="${name}"] option[value="${value}"]`).is(':disabled')) {
					jQuery(radio.$input).prop('disabled', true);
				}
			});
		});
	}
}

export default VariationRadioGroup;
