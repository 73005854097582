/* global FB, lesminimondes */
import { module as M } from 'modujs';

class ShareButton extends M {
	init() {
		window.fbAsyncInit = () => {
			FB.init({
				appId: lesminimondes.facebook_app_id,
				autoLogAppEvents: true,
				xfbml: true,
			});
		};

		this.el.addEventListener('click', () => {
			if (navigator.share) {
				navigator.share({
					title: 'Je te parraine chez les Mini Mondes :)',
					text: 'Avec le code XXX, tu auras 5€ de réduction dès 10€ d\'achat !',
					url: 'https://lesminimondes.fr'
				}).then(() => {
					console.log('Thanks for sharing!');
				})
					.catch(console.error);
			} else {
				// fallback
			}
			// FB.ui(
			// 	{
			// 		display: 'popup',
			// 		method: 'share',
			// 		href: 'https://lesminimondes.local/la-mediatheque-des-mini-mondes/',
			// 	},
			// 	response => console.log(response),
			// );
		});
	}
}

export default ShareButton;
