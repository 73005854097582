/* global Cookies */
import { module as M } from 'modujs';
import { elements } from 'scripts/config';
import $ from 'jquery';

/**
 * Notice
 *
 * @see https://github.com/woocommerce/woocommerce/blob/master/assets/js/frontend/woocommerce.js#L17
 */
class Notice extends M {
	init() {
		this.name = this.el.getAttribute('data-notice-name');

		const id = this.el.getAttribute('data-notice-id') || '';
		const cookieName = `${this.name}${id}`;

		// Check the value of that cookie and show/hide the notice accordingly
		if ('hidden' === Cookies.get(cookieName)) {
			this.close();
		} else {
			this.open();
		}

		// Set a cookie and hide the store notice when the dismiss button is clicked
		this.$('dismiss')[0]?.addEventListener('click', () => {
			Cookies.set(cookieName, 'hidden', { path: '/' });
			this.close();
		});
	}

	close() {
		elements.body.classList.remove(`Notice-${this.name}--is-open`);
		this.el.style.setProperty('display', 'none');
		this.el.parentElement.removeChild(this.el);
	}

	open() {
		$('body').addClass('shnc');
		elements.body.classList.add(`Notice-${this.name}--is-open`);
		this.el.style.removeProperty('display');
	}
}

export default Notice;
