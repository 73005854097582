import { module as M } from 'modujs';
import { disableScroll, enableScroll } from 'utils/scroll';
import { elements } from 'scripts/config';

/**
 *
 * @constructor
 * @param {object} container
 */
class Menu extends M {
	constructor(m) {
		super(m);

		this.isOpen = elements.html.classList.contains('Menu--is-open');
	}

	init() {
		this.close = this.close.bind(this);

		this.buttons = document.querySelectorAll('.js-menu-button') || [];

		this.initEvents();
	}

	/**
	 * Menu.initEvents
	 */
	initEvents() {
		// console.info('Menu.initEvents');

		// On click
		[...this.buttons].forEach(button => {
			button.addEventListener('click', () => this.toggle());
		});

		// On esc key
		document.onkeydown = ({ key }) => {
			if (27 === key) {
				this.close();
			}
		};
	}

	/**
	 * Menu.toggle
	 */
	toggle() {
		// console.info('Menu.toggle');
		if (this.isOpen) return this.close();

		return this.open();
	}

	/**
	 * Menu.open
	 */
	open() {
		// console.info('Menu.open');
		if (this.isOpen) return false;

		this.isOpen = true;

		elements.html.classList.add('Menu--is-open');

		// When menu is open, disableScroll
		disableScroll();
		this.call('stop', false, 'Scroll', 'main');

		return true;
	}

	/**
	 * Menu.close
	 */
	close() {
		// console.info('Menu.close');
		if (!this.isOpen) return false;

		this.isOpen = false;

		elements.html.classList.remove('Menu--is-open');

		// When menu is closed, enableScroll
		enableScroll();
		this.call('start', false, 'Scroll', 'main');

		return true;
	}
}

export default Menu;
