import $ from 'jquery';

class ProductFilters {
	init = () => {
		this.ready();
	}

	ready = () => {
		$(document).ready(() => {
			const filtersId = this.getMultiSelect();
			let useFilters = false;
			for(let i=0; i < filtersId.length; i+=1) {
				if($(filtersId[i].id).length > 0) {
					useFilters = true;
					$(filtersId[i].id).multiSelect({noneText : filtersId[i].label, 'buttonHTML': '<div class="multi-select-button">'});
				}
			}

			if(useFilters === true) {
				let firstCall = true;
				$('html').on('click.multiselect', () => {			
					if(firstCall === true) {
						// eslint-disable-next-line
						$('.multi-select-menuitems').each((i, sltMnuItems) => {
							// eslint-disable-next-line
							$(sltMnuItems).each((i2, sltMnuItem) => {
								// eslint-disable-next-line
								$(sltMnuItem).find('label').each((i3, oLabel) => {
									const cb = $(oLabel).find('input').detach();
									const label = $(oLabel).detach();
									const div = $('<div>', {
										class: 'multiitem',
									}).appendTo(sltMnuItems);
									$(div).append($(cb));
									$(div).append($(label));
								});
							});
						});
						firstCall = false;
					}
				  });
			}

			$('.affichage-filtres').click(() => {
				if($('#container-filters').hasClass('openFilter')){
					$('#container-filters').removeClass('openFilter');
				} else {
					$('#container-filters').addClass('openFilter')
				}
			});

			// eslint-disable-next-line	
			$('.msredirect').each((i, sltMnuItems) => {
				// eslint-disable-next-line
				$(sltMnuItems).find('label').each((i3, oLabel) => {
					$(oLabel).click(() => {
						const url = $(oLabel).parents('.multiitem').find('input').attr('value');
						window.location.href = url;
					});
				});
			})
		});
	}

	getMultiSelect = () => {
		const filtersId = [{id: '#filter-select-age', 'label': 'Âge'}, 
			{id: '#filter-select-types-categories', 'label': 'Type de produits'}, 
			{id: '#filter-select-magazines', 'label': 'Nos magazines'},
			{id: '#filter-select-types-produits', 'label': 'Nos produits'},
			{id: '#filter-select-ips', 'label': 'Nos univers'}];
		return filtersId;
	}

	getFiltersValues = () => {
		const filtersId = this.getMultiSelect();
		// eslint-disable-next-line
		let filters = [];
		for(let i=0; i < filtersId.length; i+=1){
			if($(filtersId[i].id).length > 0) {
				const val = $(filtersId[i].id).val();
				if(val.length > 0 ) {
					for(let j=0; j < val.length; j+=1) {
						filters.push(val[j]);
					}
				}
			}
		}
		return filters;
	}

	getAjaxParam = () => {

	}
}
export default ProductFilters;