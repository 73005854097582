import { module as M } from 'modujs';
import { Native } from 'locomotive-scroll';

import { elements } from 'scripts/config';

const isOntop = () => {
	const top = window.pageYOffset || document.documentElement.scrollTop;

	if (0 === top) {
		elements.html.classList.add('is-ontop');
	} else {
		elements.html.classList.remove('is-ontop');
	}
}

class Scroll extends M {
	init() {
		this.scroll = new Native({
			el: this.el,
			smooth: false,
			getDirection: true,
			resetNativeScroll: false,
		});

		this.scroll.on('scroll', ({ direction }) => {
			elements.html.setAttribute('data-direction', direction || 'down');

			isOntop();
		});

		isOntop();

		this.scroll.on('call', (func, way, obj) => {
			if (obj.el.id) {
				this.call(func[0], { way, obj, direction: this.direction }, func[1], obj.el.id);
			}
		});

		this.update = this.update.bind(this);
	}

	update() {
		return this.scroll.update();
	}

	stop() {
		return this.scroll.stop();
	}

	start() {
		return this.scroll.start();
	}

	destroy() {
		this.scroll.destroy();
	}

	scrollTo({ target, options = {} }) {
		this.scroll.scrollTo(target, options)
	}
}

export default Scroll;
